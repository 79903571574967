import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LoginComponent } from './pages/login.component';
import { PasswordResetComponent } from './pages/password-reset.component';
import { UserSessionGuard } from './guards/user-session-guard';
import { SsoLoginComponent } from './pages/sso-login.component';
import { InviteComponent } from './pages/invite.component';
import { ChurnUnsubscribeComponent } from './pages/churn-unsubscribe.component';
import { AdminLoginGuard } from './guards/admin-login.guard';
import { SsoLogoutComponent } from './pages/sso-logout.component';
import { ChangeEmailVerificationComponent } from './email-verification/change-email/change-email-verification/change-email-verification.component';
import { EmailVerificationPendingComponent } from './email-verification/email-verification-pending.component';
import { EmailVerificationSuccessComponent } from './email-verification/email-verification-success.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
    },
    {
        path: 'signup',
        component: SignupPageComponent,
        data: {
            title: 'Signup Trial',
        },
    },
    {
        path: 'login',
        canActivate: [AdminLoginGuard],
        component: LoginComponent,
        data: {
            title: 'Login',
        },
    },
    {
        path: 'sso',
        canActivate: [],
        component: SsoLoginComponent,
        data: {
            title: 'SSO',
        },
    },
    {
        path: 'password_reset/:token',
        component: PasswordResetComponent,
        data: {
            title: 'Reset password',
        },
    },
    {
        path: 'user-detail',
        loadChildren: () => import('./user-detail/user-detail.module').then((m) => m.UserDetailModule),
        canActivate: [LoggedInGuard],
        data: {
            title: 'User detail',
        },
    },
    {
        path: 'renew-plan',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./renew-plan/renew-plan.module').then((m) => m.RenewPlanModule),
        data: {
            title: 'Renew Plan',
        },
    },
    {
        path: 'vcard_update/:token',
        loadChildren: () =>
            import('./vcard-profile-picture-external/vcard-profile-picture.module').then(
                (m) => m.VcardProfilePictureModule,
            ),
        data: {
            title: 'Reset password',
        },
    },
    {
        path: 'personalize/update/:token',
        loadChildren: () =>
            import('./vcard-profile-picture-external/vcard-profile-picture.module').then(
                (m) => m.VcardProfilePictureModule,
            ),
        data: {
            title: 'Reset password',
        },
    },
    {
        path: 'trial-purchase',
        canActivate: [LoggedInGuard],
        loadChildren: () =>
            import('./global-components/product-segmentation/product-segmentation.module').then(
                (m) => m.ProductSegmentationModule,
            ),
        data: {
            title: 'Trial Purchase',
        },
    },
    {
        path: 'logout',
        canActivate: [],
        component: SsoLogoutComponent,
    },
    {
        path: 'invite/:token',
        component: InviteComponent,
        data: {
            title: 'Sign up',
        },
    },
    {
        path: 'churn-email-unsubscribe/:token',
        component: ChurnUnsubscribeComponent,
        data: {
            title: 'Churn Email Unsubscribe',
        },
    },
    {
        path: 'email_change_verify/:token',
        component: ChangeEmailVerificationComponent,
        data: {
            title: 'Change Email verification',
        },
    },
    {
        path: 'email-verification-success',
        canActivate: [LoggedInGuard],
        component: EmailVerificationSuccessComponent,
        data: {
            title: 'Email verified successfully',
        },
    },
    {
        path: 'email-verification-pending',
        canActivate: [LoggedInGuard],
        component: EmailVerificationPendingComponent,
        data: {
            title: 'Email verification pending',
        },
    },
    {
        path: '',
        canActivate: [LoggedInGuard],
        canActivateChild: [UserSessionGuard],
        component: FullLayoutComponent,
        data: {
            title: 'Home',
        },
        children: [
            {
                path: 'overview',
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'qr-codes',
                loadChildren: () => import('./qr/qr.module').then((m) => m.QRModule),
            },
            {
                path: 'qr-templates',
                loadChildren: () => import('./qr-template/qr-template.module').then((m) => m.QRTemplateModule),
            },
            {
                path: 'linkpage-templates',
                data: {
                    isCreateLinkpage: false,
                },
                loadChildren: () =>
                    import('./link-page/linkpage-templates-list/linkpage-templates-list.module').then(
                        (m) => m.LinkpageTemplatesListModule,
                    ),
            },
            {
                path: 'bulk-qr-codes',
                loadChildren: () => import('./bulk-qr/bulk-qr.module').then((m) => m.BulkQrModule),
            },
            {
                path: 'asset-library',
                loadChildren: () => import('./media-folders/media-folder.module').then((m) => m.MediaFolderModule),
            },
            {
                path: 'forms',
                loadChildren: () => import('./forms/form.module').then((m) => m.FormModule),
            },
            {
                path: 'account',
                loadChildren: () => import('./user-account/user-account.module').then((m) => m.AccountModule),
            },
            {
                path: 'analytics',
                loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
            },
            {
                path: 'landing-pages',
                loadChildren: () => import('./markdown-cards/landing-pages.module').then((m) => m.LandingPagesModule),
            },
            {
                path: 'integrations',
                loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsModule),
            },
            {
                path: 'request-logs',
                loadChildren: () => import('./request-logs/request-logs.module').then((m) => m.RequestLogsModule),
            },
            {
                path: 'api',
                loadChildren: () => import('./api/api.module').then((m) => m.ApiModule),
            },
            {
                path: 'linkpage',
                loadChildren: () => import('./link-page/link-page.module').then((m) => m.LinkPageModule),
            },
            {
                path: 'digital-business-cards',
                loadChildren: () =>
                    import('./digital-business-card/digital-business-card.module').then(
                        (m) => m.DigitalBusinessCardModule,
                    ),
            },
            {
                path: 'leads',
                loadChildren: () => import('./leads/leads.module').then((m) => m.LeadsModule),
            },
            {
                path: 'contact-manager',
                loadChildren: () => import('./leads/leads.module').then((m) => m.LeadsModule),
            },
            {
                path: 'wallet-pass-template',
                loadChildren: () =>
                    import('./wallet-pass-template/wallet-pass-template.module').then(
                        (m) => m.WalletPassTemplateModule,
                    ),
            },
            {
                path: '**',
                redirectTo: 'overview',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
