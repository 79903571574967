import {layout1TemplateHtml} from '../preview-templates/layout1-template';
import {layout2TemplateHtml} from '../preview-templates/layout2-template';
import {layout4TemplateHtml} from '../preview-templates/layout4-template';
import {layout5TemplateHtml} from '../preview-templates/layout5-template';
import {layout6TemplateHtml} from '../preview-templates/layout6-template';
import {layout9TemplateHtml} from 'app/preview-templates/layout9-template';
import {Layout11TemplateHtml} from '../preview-templates/layout11-template';
import {defaultTemplateHtml} from '../preview-templates/layout3-template';
import {layout7TemplateHtml} from '../preview-templates/layout7-template';
import {DBC_LANGUAGE_SPECIFIC_FIELDS, deepCopy, PRODUCT_STATE, Utils} from '../shared/utils';
import {TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {DigitalBusinessCardSetting} from './digital-business-card-setting/digital-business-card-setting.model';
import {Typography} from 'app/global-services/customfont';
import {layout8TemplateHtml} from '../preview-templates/layout8-template';
import {CONTACT_DETAIL} from './digital-business-card-utils';
import {AgreementLinksModal} from '../global-components/terms-service/terms-service.modal';
import {LEAD_COLLECT_TYPE} from './digital-business-cards.service';

export enum DBC_BACKGROUND_TYPE {
    Color = 'color',
    Image = 'image'
}

type contactInfoType = CONTACT_DETAIL.PHONE_V2 | CONTACT_DETAIL.EMAIL_V2 | CONTACT_DETAIL.WEBSITE_V2 | CONTACT_DETAIL.CUSTOM_FIELDS;
interface ContactInfoListType {key: contactInfoType, value: Array<Object>};

export const default_contact_info_ordering: Record<string, contactInfoType> = {
    '0': CONTACT_DETAIL.PHONE_V2,
    '1': CONTACT_DETAIL.EMAIL_V2,
    '2': CONTACT_DETAIL.WEBSITE_V2,
    '3': CONTACT_DETAIL.CUSTOM_FIELDS
}

export function buildContactInfoList({contact_info_ordering = default_contact_info_ordering, phone_v2, email_v2, website_v2, custom_fields}): Array<ContactInfoListType> {
    const contactInfoList: Array<ContactInfoListType> = [];
    const contactInfoOrdering = contact_info_ordering;

    for (const order in contactInfoOrdering) {
        switch (contactInfoOrdering[order]) {
            case CONTACT_DETAIL.PHONE_V2:
                contactInfoList.push({key: CONTACT_DETAIL.PHONE_V2, value: phone_v2});
                break;
            case CONTACT_DETAIL.EMAIL_V2:
                contactInfoList.push({key: CONTACT_DETAIL.EMAIL_V2, value: email_v2});
                break;
            case CONTACT_DETAIL.WEBSITE_V2:
                contactInfoList.push({key: CONTACT_DETAIL.WEBSITE_V2, value: website_v2});
                break;
            case CONTACT_DETAIL.CUSTOM_FIELDS:
                contactInfoList.push({key: CONTACT_DETAIL.CUSTOM_FIELDS, value: custom_fields});
                break;
        }
    }
    return contactInfoList;
}

export class LeadUserAgreementAttribute {
    user_agreement_urls: Array<AgreementLinksModal> = [new AgreementLinksModal({'label': '', 'url': ''})];
    constructor(json?) {
        if (!json) {
            return;
        }
        this.user_agreement_urls = json['user_agreement_urls'] ? json['user_agreement_urls'] : this.user_agreement_urls;
    }
}

export class DigitalBusinessCard {
    // constants
    INITIAL_DEFAULT_LANGUAGE: string;

    // class members
    id: number;
    state: PRODUCT_STATE
    url: string;
    first_name: string;
    last_name: string;
    phone: {
        mobile: string,
        work: string,
        home: string
    } = {
            mobile: '',
            work: '',
            home: ''
        };
    email: string;
    template: any = {};
    website: string;
    address_line1: string;
    address_line2: string;
    city: string;
    // state: '';
    country: string;
    zip: string;
    summary: string;
    logo_url: string;
    logo_size: number;
    designation: string;
    company: string;
    address_url: string;
    social_links: {
        facebook: string,
        instagram: string,
        twitter: string,
        linkedin: string,
        tiktok: string,
        snapchat: string,
        whatsapp: string,
        telegram: string,
        vimeo: string,
        youtube: string,
        wistia: string,
        twitch: string,
        discord: string,
        pinterest: string,
        yelp: string,
        paypal: string,
        venmo: string,
        cashapp: string,
        calendly: string,
        shopify: string,
        github: string,
        dribbble: string,
        behance: string,
        custom_url: string
    } = {
            facebook: '',
            instagram: '',
            twitter: '',
            linkedin: '',
            tiktok: '',
            snapchat: '',
            whatsapp: '',
            telegram: '',
            vimeo: '',
            youtube: '',
            wistia: '',
            twitch: '',
            discord: '',
            pinterest: '',
            yelp: '',
            paypal: '',
            venmo: '',
            cashapp: '',
            calendly: '',
            shopify: '',
            github: '',
            dribbble: '',
            behance: '',
            custom_url: '',
        }
    customizations: {
        background_color: string;
        user_info_color: string;
        secondary_color: string;
        font_style: string;
        title_font_size: number;
        font_type: string;
        custom_font_url: string;
        custom_font_style: string;
        profile_info: string;
        company_details: string;
        contact_details: string;
        button: string;
        typography: Typography
        button_color: string,
        icon_color: string,
        background: {
            type: DBC_BACKGROUND_TYPE,
            value: string
        }
    } = {
            background_color: '#9b25ff', // Primary Color
            user_info_color: '#FFFFFF', // Profile Text Color
            secondary_color: '#000000', // Secondary Color
            font_style: 'Roboto',
            title_font_size: 30,
            font_type: 'google',
            custom_font_url: '',
            custom_font_style: '',
            profile_info: 'Bold',
            company_details: 'Regular',
            contact_details: 'Medium',
            button: 'Bold',
            typography: {
                font_type: 'google',
                font_family: 'Open Sans',
                personal_info: {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 24,
                },
                company_details: {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 16,
                },
                contact_details: {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 14,
                },
                button: {
                    google_font_style: 'Regular',
                    google_font_colour: '#FFFFFF',
                    google_font_size: 16,
                },
                bio : {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 16,
                }
            },
            button_color: '#9b25ff',
            icon_color: '#9b25ff',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        };
    user_image_url: string;
    pronouns_v2: string;
    autodownload_v2: boolean = false;
    address_v2: string;
    phone_v2: Array<Object> = [];
    email_v2: Array<Object> = [];
    website_v2: Array<Object> = [];
    prefix: string;
    suffix: string;
    department: string;
    custom_fields: Array<Object> = [];
    layout: string = '3';
    jwt_update_token = '';
    organization: number;
    maintainer: number;
    threat_active: boolean;
    meta: object
    slug: string
    created: string
    updated: string
    location_enabled: boolean
    fb_pixel_id: string;
    google_conversion_id: string;
    name: string
    card_owner: any;
    address_state: string;
    is_template: boolean = false;
    views: number = 0;
    saves: number = 0;

    lead_collection: boolean;
    lead_attribute: {
        phone: boolean,
        designation: boolean,
        notes: boolean,
        connection: LEAD_COLLECT_TYPE,
        company: boolean;
    } = {
            phone: false,
            designation: false,
            notes: false,
            connection: LEAD_COLLECT_TYPE.SHARE_FIRST,
            company: false
        }
    card_template: any;
    // multi-language support
    default_language: string = null;
    language_data: {[key: string]: MultiLanguageContent} = {};

    ip_location_enabled: boolean = true;

    // Analytics views and downloads
    __views__: number = 0
    __downloads__: number = 0;
    // to check if url has to be deleted or not
    __auto_generated_slug__: boolean = true;
    tags: Array<any> = [];
    tags_data: Array<any> = [];
    contact_info_ordering: Record<string, contactInfoType> = default_contact_info_ordering;
    __contact_info_list__: Array<ContactInfoListType> = buildContactInfoList({...this});
    __show_lead_form_in_preview__: boolean = false;
    __show_new_sticky_btn__: boolean = false;
    lead_user_agreement: boolean = false;
    lead_user_agreement_attribute: LeadUserAgreementAttribute = new LeadUserAgreementAttribute()

    constructor(json?) {
        if (!json) {
            return;
        }

        this.id = json['id'] ? json['id'] : this.id;
        this.first_name = json['first_name'] ? json['first_name'] : 'John';
        this.last_name = json['last_name'] ? json['last_name'] : this.first_name ? '' : 'Doe';
        this.phone = json['phone'] ? json['phone'] : this.phone;
        this.email = json['email'] ? json['email'] : this.email;
        this.website = json['website'] ? json['website'] : this.website;
        this.address_line1 = json['address_line1'] ? json['address_line1'] : this.address_line1;
        this.address_line2 = json['address_line2'] ? json['address_line2'] : this.address_line2;
        this.city = json['city'] ? json['city'] : this.city;
        this.country = json['country'] ? json['country'] : this.country;
        this.zip = json['zip'] ? json['zip'] : this.zip;
        this.summary = json['summary'] ? json['summary'] : '';
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.logo_size = this.initializeLogoSizeFromTemplate(json['layout'], json['logo_size']);
        this.designation = json['designation'] ? json['designation'] : this.designation;
        this.company = json['company'] ? json['company'] : this.company;
        this.address_url = json['address_url'] ? json['address_url'] : this.address_url;
        this.customizations = json['customizations'] ? json['customizations'] : this.customizations;
        this.city = json['city'] ? json['city'] : this.city;
        this.user_image_url = json['user_image_url'] ? json['user_image_url'] : this.user_image_url;
        this.social_links = json['social_links'] ? json['social_links'] : this.social_links;
        this.pronouns_v2 = json['pronouns_v2'] ? json['pronouns_v2'] : this.pronouns_v2;
        this.autodownload_v2 = json.hasOwnProperty('autodownload_v2') ? json['autodownload_v2'] : this.autodownload_v2;
        this.address_v2 = json['address_v2'] ? json['address_v2'] : this.address_v2;
        this.phone_v2 = json['phone_v2'] ? json['phone_v2'] : this.phone_v2;
        this.email_v2 = json['email_v2'] ? json['email_v2'] : this.email_v2;
        this.website_v2 = json['website_v2'] ? json['website_v2'] : this.website_v2;
        this.custom_fields = json['custom_fields'] ? json['custom_fields'] : this.custom_fields;
        this.prefix = json['prefix'] ? json['prefix'] : this.prefix;
        this.suffix = json['suffix'] ? json['suffix'] : this.suffix;
        this.department = json['department'] ? json['department'] : this.department;
        this.layout = json['layout'] ? json['layout'] : this.layout;
        this.jwt_update_token = json['jwt_update_token'] ? json['jwt_update_token'] : '';
        this.organization = json['organization'] || null;
        this.maintainer = json['maintainer'];
        this.threat_active = json['threat_active'] || false;
        this.meta = json['meta'] || {}
        this.slug = json['slug'];
        this.created = json['created'];
        this.updated = json['updated'];
        this.location_enabled = json.hasOwnProperty('location_enabled') ? json['location_enabled'] : true;
        this.fb_pixel_id = json['fb_pixel_id'];
        this.google_conversion_id = json['google_conversion_id'];
        this.state = json['state'] || PRODUCT_STATE.ACTIVE;
        this.url = json['url'];
        this.name = json['first_name'] + (this.last_name.length > 0 ? ' ' + json['last_name'] : '')
        this.card_owner = json['card_owner'] ? json['card_owner'] : {};
        this.address_state = json['address_state'] ? json['address_state'] : '';
        this.is_template = json['is_template'] || false;
        this.lead_collection = json.hasOwnProperty('lead_collection') ? json['lead_collection'] : true
        this.lead_attribute = json['lead_attribute'] ? json['lead_attribute'] : this.lead_attribute;
        this.template = json['template'] || {};
        this.default_language = json['default_language'] ? json['default_language'] : this.default_language;
        this.populateLanguageData(json['language_data'] ? json['language_data'] : {} , json ? json : {});
        this.__views__ = json['__views__'] || 0;
        this.__downloads__ = json['__downloads__'] || 0;
        this.INITIAL_DEFAULT_LANGUAGE = 'en';
        this.card_template = json['card_template'] ? json['card_template'] : this.card_template;
        this.ip_location_enabled = json.hasOwnProperty('ip_location_enabled') ? json['ip_location_enabled'] : true;
        this.views = json['views'] || this.views;
        this.saves = json['saves'] || this.saves;
        this.tags = json['tags'] || this.tags;
        this.tags_data = json['tags_data'] || this.tags_data;
        this.contact_info_ordering = json['contact_info_ordering'] || this.contact_info_ordering;
        this.__contact_info_list__ = buildContactInfoList({...json}) || this.__contact_info_list__;
        this.lead_user_agreement = json.hasOwnProperty('lead_user_agreement') ? json['lead_user_agreement'] : false;
        this.set_lead_user_agreement_agreement(json)
    }

    set_lead_user_agreement_agreement(json) {
        if (!json) {
            return;
        }

        this.lead_user_agreement_attribute = json['lead_user_agreement_attribute'] || this.lead_user_agreement_attribute;
        if (!this.lead_user_agreement_attribute?.user_agreement_urls?.length) {
            this.lead_user_agreement_attribute.user_agreement_urls = [new AgreementLinksModal({ 'label': '', 'url': '' })];
        }
    }



    initializeLogoSizeFromTemplate(dbcLayout: string, logoSize: number): number {
        if (logoSize) {
            return logoSize;
        } else {
            switch (dbcLayout) {
                case '1': return 80;
                case '2': return 30;
                case '3': return 25;
                case '4': return 75;
                case '5': return 30;
                case '6': return 35;
                default: return 30;
            }
        }
    }

    populateLanguageData(language_data: any, json: DigitalBusinessCard) {
        this.language_data = {};
        if (this.default_language) {
            for (const code in language_data) {
                this.language_data[code] = new MultiLanguageContent(language_data[code]);
            }
            this.language_data[this.default_language] = new MultiLanguageContent();
            for (const field of DBC_LANGUAGE_SPECIFIC_FIELDS) {
                this.language_data[this.default_language][field] = deepCopy(this[field]);
            }
            this.language_data[this.default_language]['__contact_info_list__'] = buildContactInfoList({...json});
        }
    }

    // tslint:disable-next-line:member-ordering
    static getVcardPreviewHTML(vcard_plus, isMobile: boolean, isMultiLanguageContent: boolean = false, multiLangList?, currentLanguage?) {
        if (!(vcard_plus.phone_v2?.length > 0)) {
            if (vcard_plus.phone?.mobile?.length > 0) {
                const phone = {
                    'value': vcard_plus.phone.mobile,
                    'label': 'Mobile',
                    'valid': vcard_plus.phone.mobile.trim().length === 0 || Utils.phoneRegexWithExtension.test(vcard_plus.phone.mobile) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR
                }
                vcard_plus.phone_v2.push(phone);
            }
        }

        if (!(vcard_plus.email_v2?.length > 0)) {
            if (vcard_plus.email?.length) {
                const email = {
                    'value': vcard_plus.email,
                    'label': '',
                    'valid': vcard_plus.email.trim().length === 0 || Utils.emailRegex.test(vcard_plus.email) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR
                }
                vcard_plus.email_v2.push(email);
            }
        }

        if (!(vcard_plus.website_v2?.length > 0)) {
            if (vcard_plus.website?.length) {
                const website = {
                    'value': vcard_plus.website,
                    'label': '',
                    'valid': vcard_plus.website.trim().length === 0 || Utils.stringUrlRegex.test(vcard_plus.website) ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR
                }
                vcard_plus.website_v2.push(website);
            }
        }


        switch (vcard_plus.layout) {
            case 'individual':
            case '1':
                return layout1TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case 'employee':
            case '2':
                return layout2TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '4':
                return layout4TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '5':
                return layout5TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '6':
                return layout6TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '7':
                return layout7TemplateHtml(JSON.parse(JSON.stringify(vcard_plus)), isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '8':
                return layout8TemplateHtml(JSON.parse(JSON.stringify(vcard_plus)), isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '9':
                return layout9TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            case '11':
                return Layout11TemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
            default:
                return defaultTemplateHtml(vcard_plus, isMobile, isMultiLanguageContent , multiLangList, currentLanguage);
        }
    }

    clearModelForPost() {
        delete this.id
        if (this.__auto_generated_slug__) {
            delete this.url
        }
        delete this.__views__
        delete this.__downloads__
        delete this.__auto_generated_slug__;
        delete this.__contact_info_list__;
    }

    clearModelForPut() {
        delete this.url
        delete this.__views__
        delete this.__downloads__
        delete this.__auto_generated_slug__
        delete this.INITIAL_DEFAULT_LANGUAGE;
        delete this.__contact_info_list__;
    }

    // if fetchDataFromLanguageDataModel is called with a parameter(lang) we populate 18 fields of DBC Model with data stored in language_data[lang]
    // otherwise we populate 18 fields of DBC Model with data stored in language_data[this.default_language]
    fetchDataFromLanguageDataModel(lang?: string) {
        const language = lang ? lang : this.default_language;
        if (language && this.language_data[language]) {
            const languageData = this.language_data[language];
            const fieldsToPopulate = [
                'first_name', 'last_name', 'prefix' , 'suffix', 'designation', 'department', 'pronouns_v2', 'company', 'summary', 'logo_url',
                'phone', 'phone_v2', 'email', 'email_v2', 'website', 'website_v2', 'custom_fields', 'address_url', 'address_v2', 'social_links',
                'user_image_url', '__contact_info_list__'
            ];
            fieldsToPopulate.forEach(fieldName => {
                this[fieldName] = languageData[fieldName];
            });
        }
    }

    applyCardTemplate(template) {
        const cardTemplate = new DigitalBusinessCardTemplateModel(template);
        const restrictedFields = cardTemplate.restrictedfields;

        for (const field in restrictedFields) {
            if (field === 'logo_url' && restrictedFields[field]) {
                this.logo_size = this.initializeLogoSizeFromTemplate(this.layout, cardTemplate.logo_size);
            }
            if (restrictedFields[field]) {
                this[field] = cardTemplate[field]
            }
        }

        this.layout = cardTemplate.layout;
    }
}

export class DigitalBusinessCardBulkModel {
    background_color: string = '#9b25ff'; // Primary Color
    user_info_color: string = '#FFFFFF'; // Profile Text Color
    secondary_color: string = '#000000'; // Secondary Color
    font_style: string = 'Roboto';
    title_font_size: number = 30;
    custom_font_url: '';
    custom_font_style: '';
    profile_info: string;
    company_details: string;
    contact_details: string;
    button: string;
    font_type: string = 'google';
    typography: Typography;
    layout: string = '3';
    autodownload_v2: boolean = false;
    location_enabled: boolean = false;
    auto_send_wallet_pass: boolean = false;
    organization: number;
    logo_url: string;
    user_image_url: string;
    template: any = {};
    lead_collection: boolean;
    send_user_invite_email: boolean;
    lead_attribute: {
        phone: boolean,
        designation: boolean,
        notes: boolean,
        connection: LEAD_COLLECT_TYPE,
        company: boolean;
    } = {
            phone: false,
            designation: false,
            notes: false,
            connection: LEAD_COLLECT_TYPE.SHARE_FIRST,
            company: false
        }
    card_template: number = null;
    ip_location_enabled: boolean = false;
    is_multi_org: boolean = false;
    customizations: {
        background_color: string;
        user_info_color: string;
        secondary_color: string;
        button_color: string;
        icon_color: string
        font_style: string;
        title_font_size: number;
        font_type: string;
        custom_font_url: string;
        custom_font_style: string;
        profile_info: string;
        company_details: string;
        contact_details: string;
        button: string;
        typography: Typography
        background: {
            type: string,
            value: string,
        }
    } = {
            background_color: '#9b25ff', // Primary Color
            user_info_color: '#FFFFFF', // Profile Text Color
            secondary_color: '#000000', // Secondary Color
            font_style: 'Roboto',
            title_font_size: 30,
            font_type: 'google',
            custom_font_url: '',
            custom_font_style: '',
            profile_info: 'Bold',
            company_details: 'Regular',
            contact_details: 'Medium',
            button: 'Bold',
            button_color: '#9b25ff',
            icon_color: '#9b25ff',
            typography: {
                font_type: 'google',
                font_family: 'Open Sans',
                personal_info: {
                    google_font_style: 'Regular',
                    google_font_size: 24,
                    google_font_colour: '#000000'
                },
                company_details: {
                    google_font_style: 'Regular',
                    google_font_size: 16,
                    google_font_colour: '#000000'
                },
                contact_details: {
                    google_font_style: 'Regular',
                    google_font_size: 14,
                    google_font_colour: '#000000'
                },
                button: {
                    google_font_style: 'Regular',
                    google_font_size: 16,
                    google_font_colour: '#000000'
                },
                bio : {
                    google_font_style: 'Regular',
                    google_font_size: 16,
                    google_font_colour: '#000000'
                }
            },
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#9b25ff',
            }
        };
    lead_user_agreement: boolean = false;
    lead_user_agreement_attribute: LeadUserAgreementAttribute = new LeadUserAgreementAttribute()

    constructor(json?) {
        if (!json) {
            return;
        }
        this.background_color = json['background_color'] ? json['background_color'] : this.background_color;
        this.user_info_color = json['user_info_color'] ? json['user_info_color'] : this.user_info_color;
        this.secondary_color = json['secondary_color'] ? json['secondary_color'] :  this.secondary_color;
        this.font_style = json['font_style'] ? json['font_style'] : this.font_style;
        this.title_font_size = json['title_font_size'] ? json['title_font_size'] : this.title_font_size;
        this.font_type = json['font_type'] ? json['font_type'] : this.font_type;
        this.custom_font_url = json['custom_font_url'] ? json['custom_font_url'] : this.custom_font_url;
        this.custom_font_style = json['custom_font_style'] ? json['custom_font_style'] : this.custom_font_style;
        this.profile_info = json['profile_info'] ? json['profile_info'] : this.profile_info;
        this.company_details = json['company_details'] ? json['company_details'] : this.company_details;
        this.contact_details = json['contact_details'] ? json['contact_details'] : this.contact_details;
        this.button = json['button'] ? json['button'] : this.button;
        this.typography = json['typography'] ? json['typography'] : this.typography;
        this.layout = json['layout'] ? json['layout'] : this.layout;
        this.autodownload_v2 = json['autodownload_v2'] || false;
        this.location_enabled = json['location_enabled'] || false;
        this.auto_send_wallet_pass = json['auto_send_wallet_pass'] || false;
        this.organization = json['organization'] ? json['organization'] : this.organization;
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.user_image_url = json['user_image_url'] ? json['user_image_url'] : this.user_image_url;
        this.template = json['template'] ? json['template'] : {};
        this.lead_collection = json.hasOwnProperty('lead_collection') ? json['lead_collection'] : true;
        this.lead_attribute = json['lead_attribute'] ? json['lead_attribute'] : this.lead_attribute;
        this.send_user_invite_email = json['send_user_invite_email'] || false;
        this.card_template = json['card_template'] ? json['card_template'] : this.card_template;
        this.ip_location_enabled = json.hasOwnProperty('ip_location_enabled') ? json['ip_location_enabled'] : true;
        this.customizations = json['customizations'] ? json['customizations'] :  this.customizations;
        this.lead_user_agreement = json.hasOwnProperty('lead_user_agreement') ? json['lead_user_agreement'] : false;
        this.set_lead_user_agreement_agreement(json)
    }

    set_lead_user_agreement_agreement(json) {
        if (!json) {
            return;
        }

        this.lead_user_agreement_attribute = json['lead_user_agreement_attribute'] || this.lead_user_agreement_attribute;

        if (!this.lead_user_agreement_attribute?.user_agreement_urls?.length) {
            this.lead_user_agreement_attribute.user_agreement_urls = [new AgreementLinksModal({ 'label': '', 'url': '' })];
        }
    }

    getFormData(): FormData {
        const formData: FormData = new FormData();
        formData.append('background_color', this.background_color);
        formData.append('user_info_color', this.user_info_color);
        formData.append('secondary_color', this.secondary_color);
        formData.append('font_style', this.font_style);
        formData.append('layout', this.layout);
        formData.append('autodownload_v2', this.autodownload_v2.toString());
        formData.append('location_enabled', this.location_enabled.toString());
        formData.append('auto_send_wallet_pass', this.auto_send_wallet_pass.toString());
        formData.append('organization', this.organization.toString());
        formData.append('company_logo', this.logo_url);
        formData.append('user_image_url', this.user_image_url);
        formData.append('template', JSON.stringify(this.template));
        formData.append('lead_collection', this.lead_collection.toString());
        formData.append('lead_phone', this.lead_attribute.phone.toString());
        formData.append('lead_designation', this.lead_attribute.designation.toString());
        formData.append('lead_notes', this.lead_attribute.notes.toString());
        formData.append('lead_connection', this.lead_attribute.connection.toString());
        formData.append('lead_user_agreement', this.lead_user_agreement.toString());
        formData.append('lead_user_agreement_urls', JSON.stringify(this.lead_user_agreement_attribute.user_agreement_urls));
        formData.append('lead_company', this.lead_attribute.company.toString());
        formData.append('send_user_invite_email', this.send_user_invite_email.toString());
        formData.append('card_template', this.card_template.toString());
        formData.append('ip_location_enabled', this.ip_location_enabled.toString());
        formData.append('is_multi_org', this.is_multi_org.toString());
        formData.append('typography', JSON.stringify(this.customizations.typography));
        formData.append('customizations', JSON.stringify(this.customizations));

        return formData;
    }
}

export enum BULK_TEMPLATE_TYPE {
    CSV = 1,
    XLSX = 2
}

export class MultiLanguageContent {
    first_name: string = '';
    last_name: string = '';
    prefix: string = '';
    suffix: string = '';
    designation: string = '';
    department: string = '';
    pronouns_v2: string = '';
    company: string = '';
    summary: string = '';
    logo_url: string = '';
    phone_v2: Array<Object> = [];
    email_v2: Array<Object> = [];
    website_v2: Array<Object> = [];
    custom_fields: Array<Object> = [];
    address_url: string;
    address_v2: string;
    social_links: {
        facebook: string,
        instagram: string,
        twitter: string,
        linkedin: string,
        tiktok: string,
        snapchat: string,
        whatsapp: string,
        telegram: string,
        vimeo: string,
        youtube: string,
        wistia: string,
        twitch: string,
        discord: string,
        pinterest: string,
        yelp: string,
        paypal: string,
        venmo: string,
        cashapp: string,
        calendly: string,
        shopify: string,
        github: string,
        dribbble: string,
        behance: string,
        custom_url: string
    } = {
            facebook: '',
            instagram: '',
            twitter: '',
            linkedin: '',
            tiktok: '',
            snapchat: '',
            whatsapp: '',
            telegram: '',
            vimeo: '',
            youtube: '',
            wistia: '',
            twitch: '',
            discord: '',
            pinterest: '',
            yelp: '',
            paypal: '',
            venmo: '',
            cashapp: '',
            calendly: '',
            shopify: '',
            github: '',
            dribbble: '',
            behance: '',
            custom_url: '',
        }
    user_image_url: string = '';
    contact_info_ordering: Record<string, contactInfoType> = default_contact_info_ordering;
    __contact_info_list__: Array<any> = buildContactInfoList({...this});


    constructor(json?) {
        if (!json) {
            return;
        }
        this.first_name = json['first_name'] ? json['first_name'] : this.first_name;
        this.last_name = json['last_name'] ? json['last_name'] : this.last_name;
        this.summary = json['summary'] ? json['summary'] : this.summary;
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.designation = json['designation'] ? json['designation'] : this.designation;
        this.company = json['company'] ? json['company'] : this.company;
        this.address_url = json['address_url'] ? json['address_url'] : this.address_url;
        this.user_image_url = json['user_image_url'] ? json['user_image_url'] : this.user_image_url;
        this.social_links = json['social_links'] ? json['social_links'] : this.social_links;
        this.pronouns_v2 = json['pronouns_v2'] ? json['pronouns_v2'] : this.pronouns_v2;
        this.address_v2 = json['address_v2'] ? json['address_v2'] : this.address_v2;
        this.phone_v2 = json['phone_v2'] ? json['phone_v2'] : this.phone_v2;
        this.email_v2 = json['email_v2'] ? json['email_v2'] : this.email_v2;
        this.website_v2 = json['website_v2'] ? json['website_v2'] : this.website_v2;
        this.custom_fields = json['custom_fields'] ? json['custom_fields'] : this.custom_fields;
        this.prefix = json['prefix'] ? json['prefix'] : this.prefix;
        this.suffix = json['suffix'] ? json['suffix'] : this.suffix;
        this.department = json['department'] ? json['department'] : this.department;
        this.contact_info_ordering = json['contact_info_ordering'] || this.contact_info_ordering;
        this.__contact_info_list__ = buildContactInfoList({...json}) || this.__contact_info_list__;
    }

    fillRestrictedFieldsData(restrictedFields: DigitalBusinessCardSetting, dbcTemplate: DigitalBusinessCardTemplateModel) {
        if (!dbcTemplate) {
            return;
        }
        const exclude_fields = ['id', 'organization'];
        const v2_fields = ['pronouns', 'phone', 'email', 'website', 'address'];
        for (const field in restrictedFields) {
            if (exclude_fields.includes(field)) {
                continue;
            }
            if (restrictedFields[field]) {
                if (v2_fields.includes(field)) {
                    this[field + '_v2'] =  deepCopy(dbcTemplate[field + '_v2'])
                } else {
                    this[field] = deepCopy(dbcTemplate[field])
                }
            }
        }
    }
}

export class DigitalBusinessCardTemplateModel extends DigitalBusinessCard {
    restrictedfields: DigitalBusinessCardSetting = new DigitalBusinessCardSetting({});
    template_name: string = ''

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.template_name = json.hasOwnProperty('template_name') ? json['template_name'] : '' ;
        this.restrictedfields = json.hasOwnProperty('restrictedfields') ? json['restrictedfields'] : this.restrictedfields;
    }
}

export class DigitalBusinessCardMultiOrgBulkModel {
    is_multi_org: boolean = true;
    organization_template_map: {};
    organization: number

    constructor(json?) {
        if (!json) {
            return;
        }
        this.organization_template_map = json['organization_template_map'] ? json['organization_template_map'] : {};
        this.organization = json['organization'] ? json['organization'] : this.organization;
    }

    getFormData(): FormData {
        const formData: FormData = new FormData();
        formData.append('organization', this.organization.toString());
        formData.append('is_multi_org', this.is_multi_org.toString());
        return formData;
    }
}
