import {Component, OnInit} from '@angular/core';
import {PRODUCT_TYPES} from 'app/shared/utils';
import {SsoAuthService} from 'app/global-services/sso-auth.service';
import {datadogRum} from '@datadog/browser-rum';
import {environment} from '../../../environments/environment';

enum qrCodesSvgPaths {
    DEFAULT_STATE = '../../../assets/img/signup-flow/qr-codes-light.svg',
    HOVER_STATE = '../../../assets/img/signup-flow/qr-codes-on-hover.svg',
    CLICKED_STATE = '../../../assets/img/signup-flow/qr-codes.svg'
}

enum cardsSvgPaths {
    DEFAULT_STATE = '../../../assets/img/signup-flow/cards-light.svg',
    HOVER_STATE = '../../../assets/img/signup-flow/cards-on-hover.svg',
    CLICKED_STATE = '../../../assets/img/signup-flow/cards.svg'
}

@Component({
    selector: 'app-signup-page',
    templateUrl: './signup-page.component.html',
    styleUrls: ['./signup-page.component.scss']
})

export class SignupPageComponent implements OnInit {
    constructor(private readonly ssoAuthService: SsoAuthService) {
        this.fetchUrl();
    }

    backgroundImage: string = '../../../assets/img/signup-background.jpg'
    // default product is QR
    productType: PRODUCT_TYPES = PRODUCT_TYPES.QR;
    isProductTypeQr: boolean = true;
    qrCodesSvgPath: string = qrCodesSvgPaths.CLICKED_STATE;
    cardsSvgPath: string = cardsSvgPaths.DEFAULT_STATE;
    showProductSelector: boolean = true;
    nextUrl: string = '';
    product: string = '';
    userEmail: string = '';
    hasDbcData: boolean = false;

    ngOnInit(): void {
        this.initializeMonitoring();
    }

    initializeMonitoring() {
        datadogRum.init({
            applicationId: environment.dataDogApplicationId,
            clientToken: environment.dataDogClientToken,
            site: 'datadoghq.com',
            service: 'beaconstac-dashboard',
            env: environment.envName,
            sampleRate: 100,
            trackInteractions: true,
            defaultPrivacyLevel: 'mask-user-input'
        });

        datadogRum.startSessionReplayRecording();
    }

    handleNextCta() {
        let redirectPath = this.decodeBase64(this.nextUrl);
        const plan = this.getPlan(this.productType);
        if (this.productType === PRODUCT_TYPES.DBC) {
            redirectPath = '/digital-business-cards/my-cards/design-library?TrialSignUp=true';
        } else if (this.productType === PRODUCT_TYPES.QR) {
            const orgID = this.getOrgId(redirectPath.split('?')[1]);
            redirectPath = '/qr-codes/add?trial_first=true&qr_code_data=eyJxcl9jYW1wYWlnbl90eXBlIjoid2Vic2l0ZSIsImRhdGEiOnsidXJsIjoiIn0sImRlc2lnbiI6eyJkYXRhUGF0dGVybiI6InNxdWFyZSIsImZyYW1lU3R5bGUiOiJub25lIiwiYmFja2dyb3VuZENvbG9yIjoiI2ZmZmZmZiIsImV5ZUJhbGxTaGFwZSI6InNxdWFyZSIsImV5ZUZyYW1lU2hhcGUiOiJzcXVhcmUiLCJleWVGcmFtZUNvbG9yIjoiIzAwMDAwMCIsImV5ZUJhbGxDb2xvciI6IiMwMDAwMDAiLCJncmFkaWVudFR5cGUiOiJub25lIiwiY29sb3JMaWdodCI6IiNmZmZmZmYiLCJjb2xvckRhcmsiOiIjMDAwMDAwIiwibWFyZ2luIjowLCJmcmFtZVRleHQiOiIiLCJsb2dvQmFja2dyb3VuZCI6ZmFsc2V9fQ%3D%3D#campaign';
            if (orgID) {
                redirectPath = `/qr-codes/add?trial_first=true&qr_code_data=eyJxcl9jYW1wYWlnbl90eXBlIjoid2Vic2l0ZSIsImRhdGEiOnsidXJsIjoiIn0sImRlc2lnbiI6eyJkYXRhUGF0dGVybiI6InNxdWFyZSIsImZyYW1lU3R5bGUiOiJub25lIiwiYmFja2dyb3VuZENvbG9yIjoiI2ZmZmZmZiIsImV5ZUJhbGxTaGFwZSI6InNxdWFyZSIsImV5ZUZyYW1lU2hhcGUiOiJzcXVhcmUiLCJleWVGcmFtZUNvbG9yIjoiIzAwMDAwMCIsImV5ZUJhbGxDb2xvciI6IiMwMDAwMDAiLCJncmFkaWVudFR5cGUiOiJub25lIiwiY29sb3JMaWdodCI6IiNmZmZmZmYiLCJjb2xvckRhcmsiOiIjMDAwMDAwIiwibWFyZ2luIjowLCJmcmFtZVRleHQiOiIiLCJsb2dvQmFja2dyb3VuZCI6ZmFsc2V9fQ%3D%3D&orgID=${orgID}#campaign`;
            }
        }
        this.ssoAuthService.login(redirectPath, {signup: true, plan});
    }

    fetchUrl() {
        const searchParams = new URLSearchParams(window.location.search);
        this.nextUrl = searchParams.get('next');
        this.product = searchParams.get('product');
        this.userEmail = searchParams.get('email');
        let plan = this.getPlan(this.product);
        const redirectPath = this.decodeBase64(this.nextUrl);
        this.hasDbcData = this.hasDBCData(redirectPath.split('?')[1]);

        const trial_source = searchParams.get('trial_source');
        const utm_medium = searchParams.get('utm_medium');

        if (this.product === PRODUCT_TYPES.DBC) {
            this.isProductTypeQr = false;
            this.productType = PRODUCT_TYPES.DBC;
            this.cardsSvgPath = cardsSvgPaths.CLICKED_STATE;
            this.qrCodesSvgPath = qrCodesSvgPaths.DEFAULT_STATE;
        }
        if (!plan) {
            plan = this.getPlan(PRODUCT_TYPES.QR);
        }

        const options: any = {
            signup: true,
            plan: plan
        };
        if (trial_source !== null && utm_medium !== null && trial_source.includes('dbc_leads') && utm_medium === 'CreateNowCTA') {
            this.hideProductSelectorAndLogin(redirectPath, options);
        } else if (this.userEmail) {
            options.email = this.userEmail;
            this.hideProductSelectorAndLogin(redirectPath, options);
        } else if (this.product === PRODUCT_TYPES.LINKPAGE) {
            this.hideProductSelectorAndLogin(redirectPath, options);
        } else if (this.product === PRODUCT_TYPES.DBC && this.hasDbcData) {
            this.hideProductSelectorAndLogin(redirectPath, options);
        }
    }
    hideProductSelectorAndLogin(redirectPath, options) {
        this.showProductSelector = false;
        this.ssoAuthService.login(redirectPath, options);
    }
    hasDBCData(queryString): boolean {
        const searchParams = new URLSearchParams(queryString);
        return searchParams.get('dbc_data') !== null;
    }

    getOrgId(queryString): string {
        const searchParams = new URLSearchParams(queryString);
        return searchParams.get('orgID');
    }
    decodeBase64(encodedString) {
        try {
            const decodedString = atob(encodedString);
            return decodedString;
        } catch (error) {
            console.error('Error decoding base64 string:', error);
            return null;
        }
    }

    getPlan(productType) {
        if (productType === PRODUCT_TYPES.QR) {
            return 'SFT-STARTER-TRIAL';
        } else if (productType === PRODUCT_TYPES.DBC) {
            return 'SFT-DBC-FREE-YEAR';
        }
        // default plan is QR
        return 'SFT-STARTER-TRIAL';
    }

    handleQRCodesSelectorComponent() {
        this.productType = PRODUCT_TYPES.QR;
        this.isProductTypeQr = true;
        this.qrCodesSvgPath = qrCodesSvgPaths.CLICKED_STATE;
        this.cardsSvgPath = cardsSvgPaths.DEFAULT_STATE;
    }

    handleCardsSelectorComponent() {
        this.productType = PRODUCT_TYPES.DBC;
        this.isProductTypeQr = false;
        this.cardsSvgPath = cardsSvgPaths.CLICKED_STATE;
        this.qrCodesSvgPath = qrCodesSvgPaths.DEFAULT_STATE;
    }

    handleMouseOverQrCodes() {
        if (!this.isProductTypeQr) {
            this.qrCodesSvgPath = qrCodesSvgPaths.HOVER_STATE;
        }
    }

    handleMouseOutQrCodes() {
        if (!this.isProductTypeQr) {
            this.qrCodesSvgPath = qrCodesSvgPaths.DEFAULT_STATE;
        }
    }

    handleMouseOverCards() {
        if (this.isProductTypeQr) {
            this.cardsSvgPath = cardsSvgPaths.HOVER_STATE;
        }
    }

    handleMouseOutCards() {
        if (this.isProductTypeQr) {
            this.cardsSvgPath = cardsSvgPaths.DEFAULT_STATE;
        }
    }
}

