import {
    bufferCount,
    catchError,
    combineLatest,
    debounceTime,
    filter,
    startWith,
    switchMap,
    takeUntil
} from 'rxjs/operators';
import {
    Component,
    ElementRef,
    HostListener,
    Injectable,
    OnDestroy,
    OnInit,
    Pipe,
    PipeTransform,
    ViewChild
} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {Organization, ReducedOrganization} from '../user-account/user-organization.model';
import {HammerGestureConfig} from '@angular/platform-browser';
import {OverlayService} from '../global-services/overlay.service';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subject, Subscription, timer} from 'rxjs';
import {Animations} from '../shared/beaconstac-animations';
import {BEACONSTAC_ADMIN, BEACONSTAC_HOSTS} from '../app.constants';
import {HelpMenuDisplayService} from '../global-components/help-menu-display/help-menu-display.service';
import {HelpMenuDisplayComponent} from '../global-components/help-menu-display/help-menu-display.component';
import {environment} from '../../environments/environment';
import {IntercomService} from '../global-services/intercom.service';
import {UserSubscriptionService} from '../global-services/user-subscription-service';
import {PLAN_TYPES, User, USER_GROUPS} from '../user-account/user.model';
import {OrganizationCountModel, OrganizationCountService} from '../global-services/organization-count.service';
import {LoggedInGuard} from '../guards/logged-in.guard';
import {UserNotification} from '../global-services/user-notification.model';
import {FirebaseService} from '../global-services/firebase.service';
import {RouterReloadService} from '../global-services/router-reload.service';
import {
    BUTTON_STYLES,
    BUTTON_TYPES,
    CARET_POSITION,
    TEXT_FIELD_TYPES,
    TEXT_FIELD_VALIDATIONS,
    TOOLTIP_POSITION,
} from 'kaizen-design-system';
import {EddyCampaignTypes} from '../global-services/campaign.model';
import {DASHBOARD_MENU, PRODUCT_TYPES, PWA_MOBILE_NUDGE_SOURCE, Utils} from '../shared/utils';
import {MyOrganizationsService} from '../user-account/my-organizations.service';
import {FEATURES} from '../user-account/organization-permission-model';
import {UpsellService} from '../global-services/upsell.service';
import {
    ADD_ON_PRODUCT_TYPE,
    BeaconstacAddOnComponent,
} from '../global-components/beaconstac-add-on/beaconstac-add-on.component';
import {GoogleAnalyticsService} from '../global-services/google-analytics.service';
import {FacebookPixelService} from '../global-services/fb-pixel.service';
import {DashboardService} from '../global-services/dashboard.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ProductSegmentationService} from '../global-services/product-segmentation.service';
import {UserPostBody, UserService} from '../user-account/user.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../global-services/amplitude.service';
import {DiscardChangesService, ON_LEAVE_ACTIONS} from '../global-services/discard-changes.service';
import {NgForm} from '@angular/forms';
import {FEATURE_FLAGS} from 'app/shared/feature-flags';
import {SessionStorageService} from 'app/global-services/session-storage.service';
import {
    AvatarItemPermissions,
    NavBarItemPermissions,
    MenuOptions,
    DEFAULT_AVATAR_PERMISSIONS, DEFAULT_NAV_PERMISSIONS
} from '../global-services/dbc-rabc/dbc.rbac.model';
import {DbcRbacService} from '../global-services/dbc-rabc/dbc.rbac.service';

enum Help {
    main = 1,
    tutorials = 2,
    blogs = 3,
    resources = 4,
}

enum HelpMenuOptions {
    HELP_CENTER = 'help-center',
    LIVE_SUPPORT = 'live-support',
    BLOGS = 'blog',
    VIDEOS = 'video',
    WHATS_NEW = 'whats-new',
    REQUEST_A_FEATURE = 'request-a-feature',
}

enum NEW_USER_QR_EXP_LINK {
    BEACONSTAC_LOGO = 'BEACONSTAC_LOGO',
    ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
    REFER_A_FRIEND = 'REFER_A_FRIEND',
    LOGOUT = 'LOGOUT',
}

enum NOTIFICATIONS_IDS {
    QR_UPGRADE = '1111',
    CARDS_UPGRADE = '2222',
}

enum INVITE_COLLAB_CTA {
    NAVBAR = 'NV',
    USERMGMT = 'UM',
}

enum INVITE_TEAMMATES_TOUCHPOINTS {
    SIDENAV = 'SideNav_Invite',
    TOPNAV = 'TopNav_Invite',
}

enum QR_CREATE_CTA_VARIATIONS {
    CREATE_NEW_PRIMARY = 'create-new-primary',
    CREATE_QR_PRIMARY = 'create-qr-primary',
    CREATE_QR_TEXT = 'create-qr-text',
    NONE = 'none',
}

enum CAMPAIGN_TYPES {
    QR_CODE = 'qrCode',
    LINK_PAGE = 'linkpage',
    FORM = 'form',
}

@Pipe({ name: 'isOnHigherPlan' })
export class IsOnHigherPlan implements PipeTransform {
    transform(data: any, str: string): any {
        return data ? data.isOnHigherPlan(str) : null;
    }
}

@Component({
    selector: 'beaconstac-dashboard',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss'],
    animations: [Animations.collapse, Animations.rotate, Animations.rotateToBottom, Animations.fadeInOut],
})
export class FullLayoutComponent implements OnInit, OnDestroy {
    @ViewChild('navDrawer', { static: false }) navToggle: ElementRef;
    @ViewChild('helpModal', { static: true }) helpModal: HelpMenuDisplayComponent;
    @ViewChild('addOns', { static: true }) addOns: BeaconstacAddOnComponent;
    @ViewChild('trialSelectModal', { static: true }) trialSelectModal: ModalDirective;
    @ViewChild('newUserExpQRModal', { static: false }) newUserExpQRModal: ModalDirective;
    @ViewChild('modalToCollectBillingAddress', { static: true }) modalToCollectBillingAddress: ModalDirective;
    @ViewChild('addUserForm', { static: false }) addUserForm: NgForm;
    @ViewChild('addUserModal', { static: false }) addUserModal: ModalDirective;
    @ViewChild('userDetailModal', { static: false }) userDetailModal: ModalDirective;

    status: { isopen: boolean } = { isopen: false };

    currentYear = new Date().getUTCFullYear();
    isDropdownOpen = false;
    isSearchBarOpen: boolean = false;
    menuOptions = MenuOptions;

    private navigationStartSubscription: Subscription;
    private navigationCancelSubscription: Subscription;
    private orgsChangeSubscription: Subscription;
    private currentOrgChangeSubscription: Subscription;
    private routerReloadSubscription: Subscription;
    private trialSubscriptionExpiryId: any;

    user$: any;
    organizationsList$: any;
    dashboardVersionChange$: any;
    PLAN_TYPES: any = PLAN_TYPES;
    loading$: any;
    loadingText$: any;
    subscription$: any;
    currentCount$: any;
    hasOnlyQrAccess: boolean;
    isOwner: boolean;
    isOnStarterPlan: boolean;
    isOnTrialPlan: boolean;
    isOnPlusPlan: boolean;
    isReseller: boolean;
    isUmbrellaUser: boolean;
    hasOwnerPermissions: boolean;
    isOnQRPlan: boolean;
    getReadablePlan: any;
    hasIntegrationAccess: boolean;
    isAdmin: boolean;
    fetchingOrganizationsOnScroll: boolean = false;
    numberOfTrialDaysQR: number = 0;
    numberOfTrialDaysDBC: number = 0;

    brandLogo = '';
    brandLogoSidebar = '';
    brandSize = 'auto';
    brandMarginLeft = '';
    orgList: Array<ReducedOrganization>;
    currentOrg: ReducedOrganization;
    currentCompleteOrg: Organization;
    reloadHack = true;
    isMobileDevice: boolean = (window.innerWidth <= 768);

    // Side bar view controllers
    toggleActions: boolean = false;
    toggleQRCodes: boolean = false;
    toggleCampaigns: boolean = false;
    toggleAnalytics: boolean = true;
    toggleIntegrations: boolean = true;
    toggleTemplate: boolean = true;
    toggleHelp: boolean = true;
    toggleTrackPlan: boolean = true;
    toggleLinkPages: boolean = false;
    toggleDBC: boolean = false;
    showGiftQrInviteButton: boolean = false;

    public selectedMenu = Help.main;
    sidebarMenu = new Set(['qrcodes']);
    isSideDrawerOpen: boolean = false;

    isNotificationsDrawerOpen: boolean = false;
    isProfileDrawerOpen: boolean = false;

    // White label conditional values
    showHelpMenu: boolean = false;
    isWhiteLabelDashboard: boolean = true;
    storeUrl: string = '';

    isAdminDashboard: boolean = false;

    // Starter Plan variables
    staterPlanYearly: boolean = false;
    accountPassword: string;
    showNudge: boolean = false;

    accessList = {
        qr: true,
        markdownCard: true,
        form: true,
        bulkQR: true,
        linkpage: true,
    };

    isOrgSwitcherOpen: boolean = false;

    userNotifications: Array<UserNotification>;
    unreadNotificationCount: number;
    maxScans: number = 0;
    currentQr: number = 0;
    maxQr: number = 0;
    maxScansLabel: any = this.getLocalizedValue(this.maxScans);
    currentQrLabel: any = this.getLocalizedValue(this.currentQr);
    maxQrLabel: any = this.getLocalizedValue(this.maxQr);
    scansLabel: any;
    showSlackIntegration: boolean = true;
    pinSidebar: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject();
    organizationScrollChanged: Subject<boolean> = new Subject<boolean>();
    organizationSearchChanged: Subject<string> = new Subject<string>();
    dropdownValue = {
        name: '',
        id: -1,
    };
    accountMenuOptions = [
        {
            name: 'Account',
            id: 1,
        },
        {
            name: 'Logout',
            id: MenuOptions.LOGOUT,
        },
    ];

    accountMenuOptionsForNewUserExp = [
        {
            name: 'Account',
            id: MenuOptions.ACCOUNT_SETTINGS,
        },
        {
            name: 'View plans',
            id: MenuOptions.VIEW_PLANS,
        },
        {
            name: 'Refer a friend',
            id: MenuOptions.REFER_A_FRIEND,
        },
        {
            name: 'Logout',
            id: MenuOptions.LOGOUT,
        },
    ];

    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    HelpMenuOptions = HelpMenuOptions;
    DASHBOARD_MENU = DASHBOARD_MENU;
    filteredOrgList: Array<{}> = [];
    filteredMainOrg: Array<{}> = [];
    filteredMasterOrg: {} = {};
    totalOrgsCount: number;
    showCardsUpgradeNowButton: boolean = true;
    showQrUpgradeNowButton: boolean = true;
    showInviteCollaboratorBox: boolean = false;
    pageNumber: number = 1;
    searchedOrg: string = '';
    fetchedAllOrganizations: boolean = false;
    currentOrganizationId: number;
    isLoading: boolean = false;
    hasDBCAccess: boolean = false;
    hasQRAccess: boolean = false;
    currentDashboard = DASHBOARD_MENU.QRCODES;
    trialFirst: boolean = false;
    isLeavingNewUserQRCodeExp: boolean = false;
    leaveNewUserQRCodeExpState: NEW_USER_QR_EXP_LINK;
    SECOND: number = 1000;
    MINUTE: number = 60 * this.SECOND;
    HOUR: number = 60 * this.MINUTE;
    DAY: number = 24 * this.HOUR;
    trialEndTimeInMiliSeconds: number = Number(Date.now());
    trialEndDate;
    hasUnsavedChanges: boolean = false;
    hasMultiUserAccess: boolean;
    showTopNavInviteCollaboratorButton: boolean = false;
    currentUserCount: number;

    newUserModel: UserPostBody = {
        username: '',
        organization: 0,
        customer_plan: PLAN_TYPES.Trial,
        user_group: 'SB',
        permissions: {
            qr: true,
            cards: false,
        },
    };
    isEmailValid: boolean = true;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    emailChangeSubject = new Subject<any>();
    userRolesList = [
        { name: 'Administrator', value: 'AD' },
        { name: 'Editor', value: 'SB' },
        { name: 'Viewer', value: 'RO' },
    ];
    userRole = this.userRolesList[0];
    userGroup = ['AD', 'SB', 'RO'];
    showUserRolesList: boolean =
        this.authService.getUser().isSuperAdmin() ||
        !this.authService.getUser().isViewer(this.authService.getCurrentOrgId());

    userDetail = {
        username: this.authService.getUser().username,
        first_name: '',
        last_name: '',
    };

    dashboardMenu = [
        { name: 'QR Codes', value: DASHBOARD_MENU.QRCODES },
        { name: DASHBOARD_MENU.CARDS, value: DASHBOARD_MENU.CARDS },
    ];

    TrialInfo: any = {
        Product: 'qr',
        Heading: '',
        Description: 'Try out this',
        Details: ['first', 'second'],
        primaryCta: 'Start Trial',
        secondaryCta: 'Skip',
    };

    showModal: boolean = false;

    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;
    hasTemplatePermission: boolean = true;

    showPlanUpgradeButtonOnQR: boolean = false;
    showPlanUpgradeButtonOnCards: boolean = false;

    isOnEnterprisePlan: boolean;
    showProductSwitcher: boolean;
    isBeaconstacBrandLogo: boolean;
    navBarItems = {
        api: false,
        integrations: false,
    };

    NOTIFICATIONS_IDS = NOTIFICATIONS_IDS;
    masterOrganization;
    masterOrgReduced;
    isMasterOrgSelected: boolean = false;
    showMasterOrgSection: boolean = true;
    isUserSPUImpacted: boolean = false;
    isOnDbcTrialPlan: boolean = false;

    INVITE_TEAMMATES_TOUCHPOINTS = INVITE_TEAMMATES_TOUCHPOINTS;

    showBillingNavbar = false;
    showAnnouncementBanner = false;
    deviceResizeSubject: Subject<any> = new Subject();
    billingAddressDetails: {
        country: { name: string; code: string };
        postalCode: string;
    } = {
            country: { name: '', code: '' },
            postalCode: '',
        };
    billingDetails;
    utils = Utils;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;

    hasCardsTemplateAccess: boolean = false;
    usersAddOnModalTitle: string = 'Oops! You\'ve exceeded the limit of user seats';
    usersAddOnModalMessage: string;
    maxUser: number;
    isLinkpageRoute: boolean = false;
    isDbcDashboard: boolean = false;

    qrCreateCtaVariation: QR_CREATE_CTA_VARIATIONS = QR_CREATE_CTA_VARIATIONS.NONE;
    campaignTypes = CAMPAIGN_TYPES;
    private readonly viewDashboardAmplitudeEventFlagKey = 'hasVisitedDashboardForFirstTime';

    dbcNavItemsPermissions: NavBarItemPermissions = DEFAULT_NAV_PERMISSIONS; // Stores visibility permissions of DBC nav items based on plan
    dbcAvatarItemsPermissions: AvatarItemPermissions = DEFAULT_AVATAR_PERMISSIONS; // Stores visibility permissions of DBC avatar items based on plan

    getLocalizedValue(scans) {
        if (!isNaN(scans)) {
            if (scans >= 1000000) {
                scans = scans / 1000000;
                return Math.trunc(scans) + 'M';
            } else if (scans >= 1000) {
                scans = scans / 1000;
                return Math.trunc(scans) + 'k';
            } else {
                return Math.trunc(scans);
            }
        } else {
            return 0;
        }
    }
    dropdownIcon = {
        'qrCode': '',
        'linkpage': '',
        'form': ''
    }
    baseUrl: string = '../../assets/img/overview-page-CTA';
    QR_CREATE_CTA_VARIATIONS = QR_CREATE_CTA_VARIATIONS;
    constructor(
        private messageModal: MessageModalService,
        private authService: AuthService,
        private overlay: OverlayService,
        private facebookPixelService: FacebookPixelService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private userSubscriptionService: UserSubscriptionService,
        private routerReloadService: RouterReloadService,
        helpMenuDisplay: HelpMenuDisplayService,
        private router: Router,
        private firebaseService: FirebaseService,
        private intercomService: IntercomService,
        private organizationCountService: OrganizationCountService,
        private route: ActivatedRoute,
        private myOrganizationsService: MyOrganizationsService,
        private upsellService: UpsellService,
        private dashboardService: DashboardService,
        private userService: UserService,
        private messageService: MessageModalService,
        private productSegmentationService: ProductSegmentationService,
        private amplitudeService: AmplitudeService,
        private discardChangeService: DiscardChangesService,
        private sessionStorageService: SessionStorageService,
        private readonly dbcRBACService: DbcRbacService
    ) {
        this.authService.currentOrgId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((orgID) => {
            this.currentOrganizationId = orgID;
        });
        this.user$ = this.authService.user$;
        this.organizationsList$ = this.authService.organizationsList$;
        this.dashboardVersionChange$ = this.firebaseService.dashboardVersion$;
        this.loadingText$ = this.overlay.loadingText$;
        this.loading$ = this.overlay.loading$;
        this.subscription$ = this.userSubscriptionService.subscription$;
        this.currentCount$ = this.firebaseService.trackScanLimit$;
        this.hasOnlyQrAccess = this.authService.getUser().organization.hasOnlyQrAccess;
        this.hasCardsTemplateAccess = this.authService.getUser().organization.dbc_feature_permissions.card_template;
        this.user$.pipe(combineLatest(this.authService.organizationsList$)).subscribe((latest) => {
            if (latest[0] && this.authService.validateCurrentOrgId()) {
                this.setPlanBasedPermissions();
                this.setCampaignAccess();
                this.setAccess();
            }
        });
        this.searchedOrg = '';
        this.masterOrganization = this.authService.getOrgByID(this.authService.getUser().mainOrganization);
        this.masterOrgReduced = {
            id: this.masterOrganization?.id,
            name: this.masterOrganization?.name,
            master: !this.masterOrganization?.parent,
            role: this.getReadableGroup(this.masterOrganization?.org_user_group),
        };
        this.fetchOrganizations();
        this.setWhiteLabelConditionalValues();
        if (this.authService.getUser().isOwner()) {
            this.userSubscriptionService.getSubscriptionDetails();
            this.subscription$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
                if (res && res.isStarterTrial) {
                    this.trialEndTimeInMiliSeconds = Number(res?.qrTrialEnd) * 1000;
                    const requiredTime = Number(this.trialEndTimeInMiliSeconds) - Number(Date.now());
                    this.trialEndDate = this.getFormattedTime(requiredTime);
                    this.numberOfTrialDaysQR = Math.floor(requiredTime / this.DAY);
                }
                this.numberOfTrialDaysDBC = res?.dbcExpires || 0;
            });
        }

        this.storeUrl = environment.storeUrl;
        helpMenuDisplay.messageSource$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
            if (data) {
                this.helpModal.show(data);
            }
        });
        // this.isLinkpageRoute = this.router.url.includes('/linkpage/add') || this.router.url.includes('linkpage-templates/create');
        // Subscribe to notification Updates
        this.onUserNotifications();
        this.getScanCount();
        // Subscribe for set data updates
        this.authService.dataUpdate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.setAccess();
        });
        this.isOnDbcTrialPlan = this.authService.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC)
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkDeviceType();
        this.deviceResizeSubject.next(event);
    }

    handleWindowResize() {
        this.deviceResizeSubject.pipe(debounceTime(200))
            .subscribe(() => {
                this.isMobileDevice = window.innerWidth <= 768;
            });
    }

    handleNudgePopup(show: boolean) {
        this.showModal = show;
        this.logAmplitudeEventForOpeningPopup()
    }

    closePopupEmitter(value: boolean) {
        this.showModal = false;
    }

    logAmplitudeEventForOpeningPopup() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.GO_MOBILE_MODAL_OPENED, {
            source: 'desktop_navbar'
        })
    }

    closeSearchBar() {
        this.isSearchBarOpen = false;
        this.isSideDrawerOpen = false;
        this.showPlanUpgradeButtonOnQR = !this.isOnTrialPlan && !this.isOnEnterprisePlan && !this.isOnPlusPlan && !this.isSideDrawerOpen && !this.isSearchBarOpen;
    }

    onToggleHamburgerIcon() {
        this.isSideDrawerOpen = !this.isSideDrawerOpen;
    }

    toggleOrgSwitcher() {
        this.isOrgSwitcherOpen = !this.isOrgSwitcherOpen;
    }

    goBackToProfileMenu() {
        this.isNotificationsDrawerOpen = !this.isNotificationsDrawerOpen;
        this.isProfileDrawerOpen = true;
    }

    goBackToProfileMenuFromOrg() {
        this.isOrgSwitcherOpen = false;
    }

    redirectFromHelpSection(page) {
        switch (page) {
            case HelpMenuOptions.HELP_CENTER:
                window.open('https://docs.beaconstac.com', '_blank');
                break;
            case HelpMenuOptions.BLOGS:
                window.open('https://blog.beaconstac.com', '_blank');
                break;
            case HelpMenuOptions.VIDEOS:
                window.open('https://www.youtube.com/c/Beaconstac/featured', '_blank');
                break;
            case HelpMenuOptions.WHATS_NEW:
                window.open('https://www.uniqode.com/product-updates', '_blank');
                break;
        }
    }

    handleExpandedDropdown() {
        const url = this.router.url?.split('?')[0];
        switch (url) {
            case '/qr-codes':
            case '/analytics/qr':
                this.toggleQRCodes = false;
                break;
            case '/linkpage':
            case '/analytics/linkpage':
                this.toggleLinkPages = false;
                break;
            case '/landing-pages':
            case '/forms':
                this.toggleCampaigns = false;
                break;
        }
    }

    setPlanBasedPermissions() {
        this.isOwner = this.authService.getUser()?.isOwner();
        this.isOnStarterPlan = this.authService.getUser()?.isOnStarterPlan();
        this.isOnTrialPlan = this.authService.getUser()?.isOnTrialPlan();
        this.isOnPlusPlan = this.authService.getUser()?.isOnPlusPlan();
        this.isReseller = this.authService.getUser()?.isReseller();
        this.isUmbrellaUser = this.authService.getUser()?.isUmbrellaUser();
        this.hasOwnerPermissions = this.authService.getUser()?.hasOwnerPermissions();
        this.isAdmin = this.authService.getUser()?.isAdmin(this.authService.getCurrentOrgId());
        this.isOnQRPlan = this.authService.getUser()?.isOnQRPlan();
        this.getReadablePlan = this.authService.getUser()?.getReadablePlan();
        this.hasIntegrationAccess = this.authService.getUser()?.hasIntegrationAccess();
        this.hasDBCAccess = this.authService.getUser().hasDBCAccess(this.authService.getCurrentOrgId());
        this.hasQRAccess = this.authService.getUser().hasQRAccess(this.authService.getCurrentOrgId());
        this.isOnEnterprisePlan =
            this.authService.getUser().isOnEnterprisePlan() || this.authService.getUser().isOnDBCEnterprisePlan();

        this.setVisibilityOfUpgradeButton();
        this.setVisibilityOfGetMoreButton();
        this.setVisibilityOfInviteCollaboratorBox();

        this.showProductSwitcher = (this.hasDBCAccess && this.hasQRAccess) || this.isOwner; // 17 March 12:30 pm IST

        // if (this.isOnTrialPlan) {
        //   this.accountMenuOptions = ['Profile Settings', 'Organization', 'User Management', 'Custom Domains', 'Refer A Friend', 'Logout'];
        // } else {
        //   this.accountMenuOptions = ['Profile Settings', 'Organization', 'User Management', 'Custom Domains', 'Billing', 'Refer A Friend', 'Logout'];
        // }
        // TODO: Improve this logic
        if (!this.hasDBCAccess) {
            this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR);
        } else if (this.hasDBCAccess && !this.hasQRAccess) {
            this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC);
        } else {
            const product = this.authService.getCurrentProduct() || 'QrCodes';
            switch (product) {
                case DASHBOARD_MENU.QRCODES:
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR);
                    break;
                case DASHBOARD_MENU.CARDS:
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC);
                    break;
            }
        }
    }

    userSignedUpAfter() {
        const DBC_RELEASE_DATE = 1679036421000;
        const userJoiningDate = this.authService.getUser().date_joined;
        return userJoiningDate.getTime() > DBC_RELEASE_DATE;
    }

    navigateToOverview() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.UNIQODE_LOGO_TOP_NAV);
        this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
    }

    ngOnInit() {
        this.handleCreateQrCodeCtaLaunchDarkly();
        this.validateOrgIDParam();
        this.setDbcRbacSubscriptions();

        if (this.isEmailVerificationRequired()) {
            const pathName = this.router.url;
            this.router.navigate(['/email-verification-pending'], {
                queryParams: { next: pathName, orgID: this.currentOrganizationId },
            });
        }

        this.navigationStartSubscription = this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((event) => event instanceof NavigationStart),
                startWith(new NavigationStart(0, null)),
                bufferCount(2, 1),
            )
            .subscribe((events: NavigationStart[]) => {
                const paths = events.map((e) => (e.url ? e.url.split('?')[0] : e.url));
                if (paths[0] !== paths[1]) {
                    if (!(paths[0] === null && (paths[1] === '/' || paths[1] === '/overview')) && !(paths[0] === '/dashboard' && paths[1] === '/')) {
                        LoggedInGuard.previousPage = paths[0];
                        if (!this.hasUnsavedChanges) {
                            this.overlay.isLoading(true);
                        }
                    }
                }

                // Check to set current page value to null if navigation path changes
                if (
                    this.authService.getCurrentPage() &&
                    paths[1].indexOf(this.authService.getCurrentPage().type) === -1
                ) {
                    this.authService.setCurrentPage(null);
                }
                this.isLinkpageRoute = paths[1].includes('/linkpage/add') || paths[1].includes('linkpage-templates/create');
                this.hideSidebar();
            });

        this.navigationCancelSubscription = this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((event) => event instanceof NavigationCancel),
            )
            .subscribe((event) => {
                setTimeout(() => {
                    this.overlay.isLoading(false);
                }, 0);
            });

        this.orgsChangeSubscription = this.authService.currentOrgId$
            .pipe(takeUntil(this.ngUnsubscribe), combineLatest(this.authService.user$))
            .subscribe((latest) => {
                const [id, user] = latest;
                if (!id || !user) {
                    return;
                }
                this.searchedOrg = '';
                this.fetchOrganizations();
                this.currentCompleteOrg = this.authService.getCurrentOrganization();
                this.currentOrg = this.authService.getCurrentReducedOrganization();
                if (this.currentCompleteOrg.id === this.masterOrganization?.id) {
                    this.isMasterOrgSelected = true;
                }

                if (user.organization.whitelabel_logo_small) {
                    this.isBeaconstacBrandLogo = false;
                    this.brandLogo = user.organization.whitelabel_logo_small;
                } else {
                    this.isBeaconstacBrandLogo = true;
                    this.brandLogo = '../assets/img/uniqode-header-logo.svg';
                    this.brandLogoSidebar = '../assets/img/uniqode-small-logo-white.svg';
                }
            });

        this.currentOrgChangeSubscription = this.authService.currentOrgId$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((id) => {
                if (!id) {
                    return;
                }
                this.reloadRouterOutlet();
            });

        this.routerReloadSubscription = this.routerReloadService.reload$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.reloadRouterOutlet();
            });

        this.handleQueryParams();

        this.handleExpandedDropdown();

        this.organizationSearchChanged.pipe(debounceTime(500)).subscribe((event) => {
            this.searchedOrg = event;
            this.pageNumber = 1;
            this.fetchedAllOrganizations = false;
            this.fetchOrganizations();
        });
        this.organizationScrollChanged.pipe(debounceTime(100)).subscribe((event) => {
            this.pageNumber++;
            this.fetchingOrganizationsOnScroll = true;
            this.fetchOrganizations(true);
        });

        this.dashboardService.currentDashboard$.subscribe((res) => {
            this.isDbcDashboard  = (res === DASHBOARD_MENU.CARDS) && this.hasDBCAccess;
            if (this.currentDashboard === res) {
                return;
            }
            this.currentDashboard = res;
            this.checkForTemplatesPermission();
            this.setupNavOptions();

            this.setVisibilityOfUpgradeButton();
            this.setVisibilityOfGetMoreButton();
            this.setVisibilityOfInviteCollaboratorBox();
            this.setMenuOptions();
        });

        this.discardChangeService.showTrialSwitchModal$.subscribe((res) => {
            this.overlay.isLoading(true);
            setTimeout(() => {
                if (res) {
                    this.overlay.isLoading(false);
                    this.showTrialSwitchModal();
                }
            }, 1000);
        });

        this.discardChangeService.changeOrg$.subscribe((res) => {
            if (res) {
                this.onOrgChanged(res);
            }
        });

        this.discardChangeService.logoutAction$.subscribe(
            (res) => {
                if (res) {
                    this.logout();
                }
            },
            (error) => {
                this.logout();
            },
        );
        if (this.requireBillingDetails()) {
            this.getBillingDetails();
        }

        this.emailChangeSubject.pipe(debounceTime(1000)).subscribe((event) => {
            this.checkEmailValidity(event);
        });

        this.showPlanUpgradeButtonOnQR = this.currentDashboard === DASHBOARD_MENU.QRCODES && !this.isOnTrialPlan && !this.isOnEnterprisePlan && !this.isOnPlusPlan && !this.isSideDrawerOpen && !this.isSearchBarOpen;
        this.showPlanUpgradeButtonOnCards = (this.currentDashboard === DASHBOARD_MENU.CARDS && this.showCardsUpgradeNowButton && !this.subscription$?.dbcTrial) && !this.isSideDrawerOpen;
        this.populateUserRolesList();
        this.handleWindowResize();
        this.checkSPUImpact();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showNudge = (event.urlAfterRedirects.includes('/overview'));
            }
        });
        this.showNudge = this.router.url.includes('/overview')
        this.setDropdownIcon(); // preload the dropdown icons
    }

    checkDeviceType(): void {
        this.isMobileDevice = window.innerWidth <= 768;
    }

    handleQrCodesClick() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.QR_CODES_IN_DROPDOWN_OF_SIDENAV);
    }

    viewDashboardAmplitudeEvent() {
        if (this.trialFirst === undefined) {
            this.trialFirst = false;
        }

        // `hasVisited` is used to check if the view_dashboard event has already been logged in this session
        // to prevent duplicate logging within the same browser session.

        const hasVisited = this.sessionStorageService.getItem(this.viewDashboardAmplitudeEventFlagKey);
        if (!hasVisited) {
            this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Onboarding, AMPLITUDE_EVENTS.VIEW_DASHBOARD, {
                source : 'View Dashboard',
                qr_trial_first : this.trialFirst
            });
            this.sessionStorageService.setItem(this.viewDashboardAmplitudeEventFlagKey, true);
        }
    }

    checkSPUImpact() {
        this.userService.getSPUImpact().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (result) => {
                this.isUserSPUImpacted = result.spu_impacted;
                this.updateAnnouncementBanner();
            },
            (error) => {
                if (error?.error?.detail) {
                    this.messageModal.show(`${error.error.detail}`, 'danger');
                } else {
                    this.messageModal.show('Error getting data. Please try again or contact support.', 'danger');
                }
            }
        );
    }

    updateAnnouncementBanner() {
        const currentDate = new Date();
        const user = this.authService.getUser();

        if (!user || !user.meta || !user.meta.spu_subscription_end_date || !this.isUserSPUImpacted) {
            this.showAnnouncementBanner = false;
            return;
        }

        const endDate = new Date(user.meta.spu_subscription_end_date * 1000);
        const spuExtendedDate = user.meta.spu_extended_date ? new Date(user.meta.spu_extended_date * 1000) : null;

        const isEligibleForBanner = date => this.isInLast45Days(date, currentDate) && !this.isInLast7Days(date, currentDate);

        this.showAnnouncementBanner = spuExtendedDate ? isEligibleForBanner(spuExtendedDate) : isEligibleForBanner(endDate);
    }

    isInLast45Days(endDate: Date, currentDate: Date): boolean {
        const dateBefore45Days = new Date(endDate);
        dateBefore45Days.setDate(endDate.getDate() - 45);

        return (dateBefore45Days <= currentDate && currentDate <= endDate);
    }

    isInLast7Days(endDate: Date, currentDate: Date): boolean {
        const sevenDaysBeforeEndDate = new Date(endDate);
        sevenDaysBeforeEndDate.setDate(endDate.getDate() - 7);

        return (sevenDaysBeforeEndDate <= currentDate && currentDate <= endDate);
    }

    handleCreateQrCodeCtaLaunchDarkly() {
        this.authService.getFeatureFlag(FEATURE_FLAGS.QR_CREATE_QR_CODES_CTA, QR_CREATE_CTA_VARIATIONS.NONE).then(res => {
            this.qrCreateCtaVariation = res;
        })
    }

    onToggleSearchIcon($event){
        $event.stopPropagation();
        this.isSearchBarOpen = !this.isSearchBarOpen;
        this.showPlanUpgradeButtonOnQR = false;
    }

    setDropdownIcon() {
        const iconBaseUrl = `${this.baseUrl}/dropdownIcon`;
        this.dropdownIcon = {
            'qrCode': `${iconBaseUrl}/qrCode/qrcode.svg`,
            'linkpage': `${iconBaseUrl}/linkpage/bars.svg`,
            'form': `${iconBaseUrl}/form/thumbs-up.svg`
        }
    }

    selectDropdownIcon(campaignType: CAMPAIGN_TYPES) {
        const iconBaseUrl = `${this.baseUrl}/dropdownIcon`;
        this.setDropdownIcon();

        switch (campaignType) {
            case CAMPAIGN_TYPES.QR_CODE:
                this.dropdownIcon.qrCode = `${iconBaseUrl}/${campaignType}/qrcode-1.svg`;
                break;
            case CAMPAIGN_TYPES.LINK_PAGE:
                this.dropdownIcon.linkpage = `${iconBaseUrl}/${campaignType}/bars-1.svg`;
                break;
            case CAMPAIGN_TYPES.FORM:
                this.dropdownIcon.form = `${iconBaseUrl}/${campaignType}/thumbs-up-1.svg`;
                break;
        }
    }


    /*
     * The modalToCollectBillingAddress is visible for owners who are
     * not on DBC or QR Code trial accounts.
     */
    private requireBillingDetails(): boolean {
        const user = this.authService.getUser();
        const isOwner = user.isOwner();
        const isNotOnDBCTrialPlan = !user.isOnTrialPlan(PRODUCT_TYPES.DBC);
        const isNotOnQRTrialPlan = !user.isOnTrialPlan(PRODUCT_TYPES.QR);
        const isNotOnDBCFreePlan = !user.isOnDBCFreemiumPlan();

        return isOwner && isNotOnDBCTrialPlan && isNotOnQRTrialPlan && isNotOnDBCFreePlan;
    }

    ngAfterViewInit() {
        this.trialSubscriptionExpiryId = setInterval(() => {
            const requiredTime = Number(this.trialEndTimeInMiliSeconds) - Number(Date.now());
            this.trialEndDate = this.getFormattedTime(requiredTime);
        }, 60000);

        this.authService.hasUnsavedChanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (res) => {
                if (res) {
                    // This is used to listen browser reload and close events
                    window.onbeforeunload = (event) => {
                        event.preventDefault();
                        event.returnValue = ''; // Modern browsers require a non-empty string
                    };
                } else {
                    // Setting onbeforeunload to null as otherwise it will be keep listening to tab close event
                    window.onbeforeunload = null;
                }
                this.hasUnsavedChanges = res;
            },
            (error) => {
                this.hasUnsavedChanges = false;
            },
        );
        if (!this.authService.getUser().hasName()) {
            this.userDetailModal.show();
        }
    }

    checkForTemplatesPermission() {
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            const hasPermission = this.authService.getUser().isAdmin(this.authService.getCurrentOrgId());
            this.hasTemplatePermission = hasPermission;
            if (!hasPermission && this.router.url.includes('qr-templates')) {
                // TODO : need to move this logic to router level
                this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
                this.messageService.show('You are not allowed to access this source', 'danger');
            }
        } else {
            this.hasTemplatePermission = !this.user$.organization?.enforce_qr_templates || this.hasOwnerPermissions;
        }
    }

    ngOnDestroy() {
        clearInterval(this.trialSubscriptionExpiryId);
        this.navigationStartSubscription.unsubscribe();
        this.navigationCancelSubscription.unsubscribe();
        this.routerReloadSubscription.unsubscribe();
        if (this.orgsChangeSubscription) {
            this.orgsChangeSubscription.unsubscribe();
            this.currentOrgChangeSubscription.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    reloadRouterOutlet() {
        this.overlay.isLoading(true);
        this.authService.setCurrentPage(null);
        this.reloadHack = false;
        this.setPlanBasedPermissions();
        this.setupNavOptions();
        setTimeout(() => (this.reloadHack = true), 0);
    }

    handleQueryParams() {
        this.route.queryParams.subscribe((params) => {
            if (
                (params['message'] || '').indexOf('email was verified') &&
                params['success'] &&
                params['code'] === 'success'
            ) {
                this.router.navigate(['/email-verification-success'], {
                    queryParams: { next: '/overview', orgID: this.currentOrganizationId },
                });
            }
            this.trialFirst = params.trial_first;
            this.viewDashboardAmplitudeEvent();
        });
    }

    logout() {
        this.authService.logout();
    }

    toggleDropdown($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.status.isopen = !this.status.isopen;
    }

    showSidebar() {
        if (!this.pinSidebar && !document.body.classList.contains('sidebar-mobile-show')) {
            this.navToggle?.nativeElement.click();
        }
    }

    hideSidebar() {
        if (!this.pinSidebar && document.body.classList.contains('sidebar-mobile-show')) {
            this.navToggle?.nativeElement.click();
        }
    }

    validateOrgIDParam() {
        this.route.queryParams.subscribe((param) => {
            if (param && param.orgID) {
                this.isLoading = true;
                this.authService.fetchOrganizationByID(Number(param.orgID)).subscribe(
                    (res) => {
                        this.authService.setCurrentOrgObjInLocalStorage(res);
                        this.isLoading = false;
                    },
                    (error) => {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: { orgID: null },
                            queryParamsHandling: 'merge',
                        });
                        this.authService.setDefaultUserOrg();
                        this.isLoading = false;
                    },
                );
            }
        });
    }

    updateURLParams(id: number) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { orgID: id, filters: null },
            queryParamsHandling: 'merge',
        });
    }

    onOrgChanged(organization) {
        this.isMasterOrgSelected = organization.id === this.masterOrganization?.id;
        if (organization.id !== this.authService.getCurrentOrgId()) {
            if (this.hasUnsavedChanges) {
                this.currentOrg = this.authService.getCurrentReducedOrganization();
                this.discardChangeService.show(
                    'Leave this page?',
                    'Leaving will result in losing the recent changes you have made.',
                    null,
                    ON_LEAVE_ACTIONS.ORGANIZATION_CHANGE,
                    organization,
                );
                return;
            }
            if (document.getElementById('beaconstac-search-organization')) {
                document.getElementById('beaconstac-search-organization')['value'] = '';
            }
            this.authService.setCurrentOrgObjInLocalStorage(this.authService.getOrgByID(organization.id));
            this.updateURLParams(organization.id);
            this.setPlanBasedPermissions();
            this.setMenuOptions();
            this.setCampaignAccess();
            this.fetchedAllOrganizations = false;
            this.pageNumber = 1;
            this.searchedOrg = '';
            this.isOrgSwitcherOpen = false;
            this.isProfileDrawerOpen = false;
        }
    }

    setWhiteLabelConditionalValues() {
        const host = window.location.hostname.toLowerCase();
        if (!Utils.isWhiteLabeledDashboard()) {
            this.showHelpMenu = false;
            this.isWhiteLabelDashboard = false;
        }
        this.isAdminDashboard =
            [BEACONSTAC_ADMIN, BEACONSTAC_HOSTS[2], BEACONSTAC_HOSTS[3]].indexOf(host) !== -1 ||
            host.includes(BEACONSTAC_HOSTS[5]);
    }

    get Help() {
        return Help;
    }

    switchMenu(menu, event): void {
        event.stopPropagation();
        this.selectedMenu = menu;
    }

    startNewIntercomConversation() {
        this.intercomService.startNewConversation();
    }

    private setAccess() {
        this.organizationCountService
            .getCounts()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((organizationCount: OrganizationCountModel) => {
                this.currentQr = organizationCount.dynamic_qrcode_count;
                this.currentQrLabel = this.getLocalizedValue(this.currentQr);
                this.accessList.bulkQR = organizationCount.bulkqr_count > 0;
                this.currentUserCount = organizationCount.qr_user_count;

                if (this.isOnStarterPlan && (this.currentQr === 2 || this.currentQr === 3)) {
                    this.facebookPixelService.eventEmitter(`Starter plan ${this.currentQr} QR codes created`);
                    this.googleAnalyticsService.eventEmitter(`Starter plan ${this.currentQr} QR codes created`);
                }
            });
        // this.setCampaignAccess()
        this.hasCardsTemplateAccess = this.authService.getUser().organization.dbc_feature_permissions.card_template;
        this.userService.getDetail().pipe(takeUntil(this.ngUnsubscribe)).subscribe((initialFetchedUser) => {
            const user = new User(initialFetchedUser);
            this.maxUser = user.maxUsersAllowed;
        });
    }

    toggleSidebarPin() {
        setTimeout(() => {
            this.pinSidebar = !this.pinSidebar;
        }, 0);
    }

    openSidebar() {
        setTimeout(() => {
            this.pinSidebar = false;
        }, 0);
    }

    onUserNotifications() {
        this.firebaseService.userNotification$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userNotifications = res;
            // Upgrade status notification
            this.subscription$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscription) => {
                if (!subscription) {
                    return;
                }
                if (!subscription['isStarterTrial'] && !subscription['dbcTrial']) {
                    return;
                }

                const upgradeNotification: Array<UserNotification> = [];

                if (subscription['isStarterTrial']) {
                    const qrNotification = {
                        title:
                            subscription['expires'] > 0
                                ? `QR CODES WILL EXPIRE IN ${subscription['expires']} ${
                                    subscription['expires'] > 1 ? 'DAYS' : 'DAY'
                                }`
                                : 'QR CODES WILL EXPIRE TODAY',
                        message: 'Upgrade now to a paid plan to keep your QR codes active',
                        link: `${window.origin}/account/upgrade?source=notification-trialexpiry-upgrade-cta&product=${DASHBOARD_MENU.QRCODES}`,
                        read: false,
                        created: new Date().getTime(),
                        updated: new Date().getTime(),
                    };

                    const upgradeQRNotification = new UserNotification(qrNotification, NOTIFICATIONS_IDS.QR_UPGRADE);
                    upgradeNotification.push(upgradeQRNotification);
                }

                if (subscription['dbcTrial']) {
                    const dbcNotification = {
                        title:
                            subscription['dbcExpires'] > 0
                                ? `CARDS WILL EXPIRE IN ${subscription['dbcExpires']} ${
                                    subscription['dbcExpires'] > 1 ? 'DAYS' : 'DAY'
                                }`
                                : 'CARDS WILL EXPIRE TODAY',
                        message: 'Upgrade now to a paid plan to keep your cards active',
                        link: `${window.origin}/account/upgrade?source=notification-trialexpiry-upgrade-cta&product=${DASHBOARD_MENU.CARDS}`,
                        read: false,
                        created: new Date().getTime(),
                        updated: new Date().getTime(),
                    };

                    const upgradeDBCNotification = new UserNotification(
                        dbcNotification,
                        NOTIFICATIONS_IDS.CARDS_UPGRADE,
                    );
                    upgradeNotification.push(upgradeDBCNotification);
                }

                if (this.userNotifications) {
                    for (const notificationObject of upgradeNotification) {
                        if (!this.userNotifications.find((noti) => noti.id === notificationObject.id)) {
                            this.userNotifications.push(notificationObject);
                        }
                    }
                } else {
                    this.userNotifications = upgradeNotification;
                }
                if (this.userNotifications) {
                    this.unreadNotificationCount = this.userNotifications.filter((n) => n.read === false).length;
                }
            });
            // Upgrade status notification end

            if (this.userNotifications) {
                this.unreadNotificationCount = this.userNotifications.filter(
                    (notification) => notification.read === false,
                ).length;
            }
        });
    }

    markAllNotificationsAsRead() {
        for (const notification of this.userNotifications) {
            notification.markAsRead();
        }
        this.isNotificationsDrawerOpen = false;
    }

    onReload() {
        location.reload();
    }

    getScanCount() {
        this.maxScans = this.authService.getUser().maxScansAllowed;
        this.maxQr = this.authService.getUser().maxQRAllowed;
        this.maxScansLabel = this.getLocalizedValue(this.maxScans);
        this.maxQrLabel = this.getLocalizedValue(this.maxQr);
    }

    isEmailVerificationRequired() {
        return false;
        // const user = this.authService.getUser();
        // // 1640131200000 - 22nd of December 2021
        // const dateCheckForVerification = (user.date_joined.getTime() > 1640131200000);
        // const durationCheckForVerification = (new Date().getTime() - user.date_joined.getTime() > 864000000);
        // return user.isOnTrialPlan() && user.isOwner() && !user.email_verified && !this.authService.isSafeSuperUser() && dateCheckForVerification && durationCheckForVerification;
    }

    toggleDrawer() {
        this.isSideDrawerOpen = !this.isSideDrawerOpen;
    }

    selectOption(value) {
        switch (value.id) {
            case MenuOptions.ACCOUNT_SETTINGS:
                if (!this.trialFirst) {
                    this.router.navigate(['/account'], { queryParams: { orgID: this.currentOrganizationId } });
                } else {
                    this.newUserExpQRModal.show();
                    this.leaveNewUserQRCodeExpState = NEW_USER_QR_EXP_LINK.ACCOUNT_SETTINGS;
                }
                break;
            case MenuOptions.ORGANIZATIONS:
                this.router.navigate(['/account/organizations'], {
                    queryParams: { orgID: this.currentOrganizationId },
                });
                break;
            case MenuOptions.USER_MANAGEMENT:
                this.router.navigate(['/account/users'], { queryParams: { orgID: this.currentOrganizationId } });
                break;
            case MenuOptions.CUSTOM_DOMAINS:
                this.router.navigate(['/account/domains'], { queryParams: { orgID: this.currentOrganizationId } });
                break;
            case MenuOptions.BILLING:
                this.router.navigate(['/account/billing'], { queryParams: { orgID: this.currentOrganizationId } });
                break;
            case MenuOptions.VIEW_PLANS:
                this.openUpgradePage('viewplan-buynow-cta');
                break;
            case MenuOptions.REFER_A_FRIEND:
                if (!this.trialFirst) {
                    this.router.navigate(['/account/refer'], { queryParams: { orgID: this.currentOrganizationId } });
                } else {
                    this.leaveNewUserQRCodeExpState = NEW_USER_QR_EXP_LINK.REFER_A_FRIEND;
                    this.newUserExpQRModal.show();
                }
                break;
            case MenuOptions.LOGOUT:
                if (this.hasUnsavedChanges) {
                    this.discardChangeService.show(
                        'Leave this page?',
                        'Leaving will result in losing the recent changes you have made.',
                        null,
                        ON_LEAVE_ACTIONS.LOGOUT,
                    );
                } else if (!this.trialFirst) {
                    this.logout();
                } else {
                    this.leaveNewUserQRCodeExpState = NEW_USER_QR_EXP_LINK.LOGOUT;
                    this.newUserExpQRModal.show();
                }
                break;
            case MenuOptions.NOTIFICATIONS:
                this.isProfileDrawerOpen = !this.isProfileDrawerOpen;
                this.isNotificationsDrawerOpen = !this.isNotificationsDrawerOpen;
                break;
        }
        this.dropdownValue = {
            name: '',
            id: -1,
        };
        this.isProfileDrawerOpen = false;
    }

    setDbcRbacSubscriptions() {
        this.dbcRBACService.navBarPermission$.pipe(takeUntil(this.ngUnsubscribe)).subscribe( navPermissions => {
            this.dbcNavItemsPermissions = navPermissions;
        }, error => {})

        this.dbcRBACService.avatarPermission$.pipe(takeUntil(this.ngUnsubscribe)).subscribe( avatarPermissions => {
            this.dbcAvatarItemsPermissions = avatarPermissions;
            this.setMenuOptions();
        }, error => {})
    }
    setMenuOptions() {
        this.accountMenuOptions = [
            {
                name: 'Account',
                id: MenuOptions.ACCOUNT_SETTINGS,
            },
        ];

        if (this.isMobileDevice){
            const notificationOption = { name: 'Notifications', id: MenuOptions.NOTIFICATIONS };
            this.accountMenuOptions.unshift(notificationOption);
            this.accountMenuOptionsForNewUserExp.unshift(notificationOption);
        }

        if (this.hasOwnerPermissions) {
            this.accountMenuOptions.push({
                name: 'Organization',
                id: MenuOptions.ORGANIZATIONS,
            });
        }
        if (this.isAdmin || this.isOwner) {
            this.accountMenuOptions.push({
                name: 'User Management',
                id: MenuOptions.USER_MANAGEMENT,
            });
        }
        if (this.hasOwnerPermissions) {
            this.accountMenuOptions.push({
                name: 'Domains',
                id: MenuOptions.CUSTOM_DOMAINS,
            });
        }
        if (this.isOwner) {
            this.accountMenuOptions.push({
                name: 'View plans',
                id: MenuOptions.VIEW_PLANS,
            });
        }
        if (this.isOwner) {
            this.accountMenuOptions.push({
                name: 'Billing',
                id: MenuOptions.BILLING,
            });
        }
        if (this.isOnTrialPlan || this.isOnStarterPlan) {
            this.accountMenuOptions.push({
                name: 'Refer a friend',
                id: MenuOptions.REFER_A_FRIEND,
            });
        }
        this.accountMenuOptions.push({
            name: 'Logout',
            id: MenuOptions.LOGOUT,
        });

        // filter out the options which are not available in DBC plan
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            this.accountMenuOptions = this.accountMenuOptions.filter((option) => {
                return this.dbcAvatarItemsPermissions[option.id];
            });

            // Changing View Plans to Pricing and Plans for new DBC pricing.
            this.accountMenuOptions = this.accountMenuOptions.map((option) => {
                if (option.id === MenuOptions.VIEW_PLANS) {
                    option.name = 'Pricing & Plans';
                }
                return option;
            });
        }
    }

    private setCampaignAccess() {
        const currentOrgFeaturePermission = this.authService.getCurrentOrganization().feature_permissions;
        this.accessList.markdownCard = currentOrgFeaturePermission?.hasCampaignAccess(EddyCampaignTypes.Card);
        this.accessList.form = currentOrgFeaturePermission?.hasCampaignAccess(EddyCampaignTypes.Form);
        this.accessList.linkpage = currentOrgFeaturePermission?.hasCampaignAccess(EddyCampaignTypes.Linkpage);
    }

    goToReferPage() {
        if (this.router.url !== '/account/refer') {
            this.overlay.isLoading(true);
            this.router.navigateByUrl('/account/refer');
        }
    }

    fetchOrganizations(onScroll: boolean = false) {
        const searchParams = { name__icontains: this.searchedOrg.toLowerCase() };
        this.myOrganizationsService.getList(this.pageNumber, 20, searchParams, false, 'name').subscribe(
            (res) => {
                if (this.searchedOrg !== '') {
                    if (this.isMasterOrgSelected) {
                        // won't be altering the UI for this case
                    } else {
                        let isMasterOrgInSearchList = false;
                        for (const org of res.objects) {
                            isMasterOrgInSearchList = org.id === this.masterOrganization?.id;
                        }
                        this.showMasterOrgSection = isMasterOrgInSearchList ? true : false;
                    }
                } else {
                    this.showMasterOrgSection = true;
                }

                if (this.searchedOrg === '') {
                    this.totalOrgsCount = res.totalCount;
                }
                if (!onScroll) {
                    this.filteredOrgList = [];
                }

                if (this.pageNumber === res.pageCount) {
                    this.fetchedAllOrganizations = true;
                }
                let isCurrentOrgInList = false;
                for (const org of res.objects) {
                    // not pushing master organization to this.filteredOrgList as it is handled separately
                    if (org.id === this.masterOrganization?.id) {
                        continue;
                    }
                    if (org.id === this.currentOrganizationId) {
                        isCurrentOrgInList = true;
                        this.filteredOrgList.unshift({
                            id: org.id,
                            name: org.name.length <= 24 ? org.name : org.name.substr(0, 24) + '...',
                            nameTooltip: org.name,
                            master: !org.parent,
                            role: this.getReadableGroup(org.org_user_group),
                        });
                    } else {
                        this.filteredOrgList.push({
                            id: org.id,
                            name: org.name.length <= 24 ? org.name : org.name.substr(0, 24) + '...',
                            nameTooltip: org.name,
                            master: !org.parent,
                            role: this.getReadableGroup(org.org_user_group),
                        });
                    }
                }
                if (!isCurrentOrgInList && !onScroll && this.currentCompleteOrg.id !== this.masterOrganization?.id) {
                    this.filteredOrgList.unshift({
                        id: this.currentCompleteOrg.id,
                        name:
                            this.currentCompleteOrg.name.length <= 24
                                ? this.currentCompleteOrg.name
                                : this.currentCompleteOrg.name.substr(0, 24) + '...',
                        master: !this.currentCompleteOrg.parent,
                        role: this.getReadableGroup(this.currentCompleteOrg.org_user_group),
                    });
                    this.authService.setOrganizationsList(res.objects);
                    this.authService.appendOrgToList(this.currentCompleteOrg);
                } else if (!onScroll) {
                    this.authService.setOrganizationsList(res.objects);
                } else {
                    this.authService.appendOrganizationsToList(res.objects);
                }
                this.fetchingOrganizationsOnScroll = false;
            },
            (error) => {
                this.fetchingOrganizationsOnScroll = false;
                console.log(error);
            },
        );
    }

    getReadableGroup(user_group): string {
        switch (user_group) {
            case USER_GROUPS.Admin:
                return 'Admin';
            case USER_GROUPS.Owner:
                return 'Owner';
            case USER_GROUPS.ReadOnly:
                return 'Viewer';
            case USER_GROUPS.SandBox:
                return 'Editor';
            default:
                return '';
        }
    }

    handleBeaconstacLogo() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.UNIQODE_LOGO_TOP_NAV);
        if (!this.trialFirst) {
            this.router.navigate(['/overview'], {queryParams: {orgID: this.currentOrganizationId}});
        } else {
            this.leaveNewUserQRCodeExpState = NEW_USER_QR_EXP_LINK.BEACONSTAC_LOGO;
            this.newUserExpQRModal.show();
        }
    }

    handleNewUserExpQRModal() {
        this.authService.hasUnsavedChangesSource.next(false);
        switch (this.leaveNewUserQRCodeExpState) {
            case NEW_USER_QR_EXP_LINK.BEACONSTAC_LOGO:
                this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
                break;
            case NEW_USER_QR_EXP_LINK.ACCOUNT_SETTINGS:
                this.router.navigate(['/account'], { queryParams: { orgID: this.currentOrganizationId } });
                break;
            case NEW_USER_QR_EXP_LINK.REFER_A_FRIEND:
                this.router.navigate(['/account/refer'], { queryParams: { orgID: this.currentOrganizationId } });
                break;
            case NEW_USER_QR_EXP_LINK.LOGOUT:
                this.logout();
                break;
        }
    }

    getMoreQrCodes() {
        if (this.currentDashboard === DASHBOARD_MENU.QRCODES) {
            const hasMultiQrAccess = this.upsellService.isSupportedOnPlan(FEATURES.multi_qr);
            if (hasMultiQrAccess) {
                this.addOns.show(
                    ADD_ON_PRODUCT_TYPE.QR_CODES,
                    'Add additional QR codes and scans to your plan',
                    `You have used ${this.currentQr} of ${this.maxQr} QR Codes. You can add QR codes and scans to your current plan or upgrade your current plan`,
                    'getmorecodes-addon-upsell',
                );
            } else {
                if (this.trialFirst) {
                    window.open('/account/upgrade?source=getmorecodes-cta', '_blank', 'noopener noreferrer');
                } else {
                    this.openUpgradePage('getmorecodes-cta');
                }
            }
        } else {
            this.openUpgradePage('get-more-cards-cta');
        }
    }

    getMoreData() {
        if (this.fetchedAllOrganizations || this.fetchingOrganizationsOnScroll) {
            return;
        }
        this.organizationScrollChanged.next(true);
    }

    searchOrganization(event) {
        this.organizationSearchChanged.next(event);
    }

    toggleDashboard(event) {
        switch (event.value) {
            case DASHBOARD_MENU.QRCODES:
                if (this.hasQRAccess) {
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR);
                    if (!this.router.url.includes('overview')) {
                        this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
                    }
                } else if (this.authService.getUser().hadQRAccess()) {
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR);
                    const queries = {
                        source: 'expiredplan-page-upgrade-cta',
                    };
                    this.router.navigate(['/renew-plan'], { queryParams: queries });
                    return;
                } else {
                    this.handleSwitchTrial(DASHBOARD_MENU.QRCODES);
                }

                break;

            case DASHBOARD_MENU.CARDS:
                if (this.hasDBCAccess) {
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC);
                    if (!this.router.url.includes('overview')) {
                        this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
                    }
                } else if (this.authService.getUser().hadDBCAccess()) {
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC);
                    const queries = {
                        source: 'expiredplan-page-upgrade-cta',
                    };
                    this.router.navigate(['/renew-plan'], { queryParams: queries });
                    return;
                } else {
                    this.handleSwitchTrial(DASHBOARD_MENU.CARDS);
                }

                break;
        }
        this.checkForTemplatesPermission();
        this.isSideDrawerOpen = false;
    }

    handleSwitchTrial(product: DASHBOARD_MENU) {
        if (this.hasUnsavedChanges) {
            this.discardChangeService.show(
                'Leave this page?',
                'Leaving will result in losing the recent changes you have made.',
                null,
                ON_LEAVE_ACTIONS.OPEN_TRIAL_SWITCH,
            );
        } else {
            this.showTrialSwitchModal(product);
        }
    }

    showTrialSwitchModal(product?: DASHBOARD_MENU) {
        if (!product) {
            const current_product = this.authService.getCurrentProduct();
            product = current_product === DASHBOARD_MENU.CARDS ? DASHBOARD_MENU.QRCODES : DASHBOARD_MENU.CARDS;
        }
        switch (product) {
            case DASHBOARD_MENU.QRCODES:
                this.TrialInfo.Product = 'QR Codes';
                this.TrialInfo.Heading = 'Uniqode QR Codes';
                this.TrialInfo.Description = 'A QR Code management platform to';
                this.TrialInfo.Details = [
                    'Create custom codes for your use case',
                    'Edit destination later, even after printing ',
                    'Track scans in real-time ',
                    ...(this.isOnEnterprisePlan ? ['Integrate with 5000+ apps'] : []),
                ];
                this.TrialInfo.primaryCta = this.isOnEnterprisePlan ? 'Schedule Demo' : 'Start Trial';
                this.TrialInfo.secondaryCta = this.isOnEnterprisePlan ? 'Close' : 'Skip';
                this.trialSelectModal.show();
                this.isSideDrawerOpen = false;
                break;
            case DASHBOARD_MENU.CARDS:
                this.TrialInfo.Product = 'Cards';
                this.TrialInfo.Heading = 'Uniqode Cards';
                this.TrialInfo.Description = 'A digital business card solution to';
                this.TrialInfo.Details = [
                    'Create personalized cards  ',
                    'Share with prospects via wallet pass ',
                    ...(this.isOnEnterprisePlan ? ['Collect contacts & send to your CRM'] : []),
                    'Get in-depth analytics ',
                    ...(this.isOnEnterprisePlan ? ['Scale with Azure Active Directory'] : []),
                ];
                this.TrialInfo.primaryCta = this.isOnEnterprisePlan ? 'Schedule Demo' : 'Get Started';
                this.TrialInfo.secondaryCta = this.isOnEnterprisePlan ? 'Close' : 'Skip';
                this.trialSelectModal.show();
                this.isSideDrawerOpen = false;
                break;
        }
    }

    handleTrialCreateModal() {
        this.trialSelectModal.hide();
        if (this.isMobileDevice){
            this.isSideDrawerOpen = true;
        }
        this.authService.allowRedirectForcefully.next(true);
        if (this.isOnEnterprisePlan) {
            this.redirectToScheduleDemo();
            return;
        }
        this.overlay.isLoading(true);
        if (this.TrialInfo.Product === 'QR Codes') {
            const body = {
                plan: PLAN_TYPES.Trial,
            };
            this.productSegmentationService
                .post(body)
                .pipe()
                .subscribe(
                    (res) => {
                        this.userService.getDetail().subscribe(() => {
                            this.messageService.show('Upgraded to QR Codes trial');
                            this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR);
                            this.logToAmplitude(true);
                            this.router.navigate(['/qr-codes/add'], {
                                queryParams: { orgID: this.currentOrganizationId },
                            });
                        });
                    },
                    (error) => {
                        console.log('error');
                        this.logToAmplitude(false);
                        this.authService.allowRedirectForcefully.next(false);
                        this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
                    },
                );
        } else if (this.TrialInfo.Product === 'Cards') {
            // start dbc free account subscription
            const body = {
                plan: PLAN_TYPES.DBCFreemium
            }
            this.productSegmentationService.post(body).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.userService.getDetail().subscribe(() => {
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC);
                    this.router.navigate(['/digital-business-cards/my-cards/layout'], {queryParams: {trialFirst: 'true', orgID: this.authService.getCurrentOrgId()}});
                })
            }, error => {
                this.messageService.show('Error creating Free account', 'danger');
                this.router.navigate(['/overview'], {queryParams: {orgID: this.authService.getCurrentOrgId()}});
            })
        }
    }

    redirectToScheduleDemo() {
        // Redirect Enterprise customers to schedule demo on calendar link
        window.open('https://meetings.salesloft.com/beaconstac/ericblumenthal', '_blank');
    }

    getFormattedTime(time: number): string {
        const days = Math.floor(time / this.DAY);
        const hours = Math.floor((time % this.DAY) / this.HOUR);
        const minutes = Math.floor((time % this.HOUR) / this.MINUTE);
        return `${days}d : ${hours}hr : ${minutes}m`;
    }

    logToAmplitude(isSuccess: boolean) {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, AMPLITUDE_EVENTS.TRIAL_START, {
            source: 'product-switcher',
            product: 'qrcodes',
            success: isSuccess,
        });
    }

    logAmplitudeEventForCreateQRcta(variation: QR_CREATE_CTA_VARIATIONS, value: string) {
        let eventName = '';
        switch (variation) {
            case QR_CREATE_CTA_VARIATIONS.CREATE_QR_TEXT:
                eventName = AMPLITUDE_EVENTS.CREATE_QR_CODE_SIDENAV_CLICKS;
                break;
            case QR_CREATE_CTA_VARIATIONS.CREATE_QR_PRIMARY:
                eventName =  AMPLITUDE_EVENTS.CREATE_QR_PRIMARY_CTA_CLICKS;
                break;
            case QR_CREATE_CTA_VARIATIONS.CREATE_NEW_PRIMARY:
                eventName = AMPLITUDE_EVENTS.CREATE_ALL_CLICKS;
                break;
        }
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, eventName, value ? { value: value } : {});
    }

    private setupNavOptions() {
        this.resetNavOptions();

        if (this.authService.getUser().isAdmin(this.authService.getCurrentOrgId())) {
            this.navBarItems.integrations = true;

            if (this.currentDashboard === DASHBOARD_MENU.QRCODES || this.currentDashboard === DASHBOARD_MENU.CARDS) {
                this.navBarItems.api = true;
            }
        }
    }

    private resetNavOptions() {
        this.navBarItems = {
            api: false,
            integrations: false,
        };
    }

    private setVisibilityOfUpgradeButton() {
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            if (!this.authService.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC)) {
                this.showCardsUpgradeNowButton = !this.authService.getUser().isOnDBCEnterprisePlan();
            }
        } else {
            this.showQrUpgradeNowButton = !(
                this.authService.getUser().isOnEnterprisePlan() || this.authService.getUser().isOnPlusPLan()
            );
        }
    }

    private setVisibilityOfInviteCollaboratorBox() {
        const user = this.authService.getUser();
        this.hasMultiUserAccess = user.hasMultiUserAccess();
        if (this.currentDashboard === DASHBOARD_MENU.QRCODES) {
            this.showTopNavInviteCollaboratorButton = user.isSuperAdmin() || user.isOwner() || user.isAdmin(this.currentOrganizationId) || user.hasInviteCollaboratorAccess();
            if (!user.isOnPlusPLan() && !user.isOnQREnterprisePlan()) {
                this.showInviteCollaboratorBox = false;
            } else {
                if (user.isSuperAdmin() || user.isOwner()) {
                    this.showInviteCollaboratorBox = true;
                } else if (user.isAdmin(this.currentOrganizationId)) {
                    this.showInviteCollaboratorBox = true;
                } else {
                    this.showInviteCollaboratorBox = user.hasInviteCollaboratorAccess();
                }
            }
        } else {
            this.showTopNavInviteCollaboratorButton = false;
            this.showInviteCollaboratorBox = user.hasInviteCollaboratorAccess(PRODUCT_TYPES.DBC);
        }
    }

    private setVisibilityOfGetMoreButton() {
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            this.showGiftQrInviteButton = false;
        } else {
            this.showGiftQrInviteButton = !this.authService.getUser().isOnEnterprisePlan();
        }
    }

    openUpgradePage(source) {
        window.open(
            `/account/upgrade?source=${source}&orgId=${this.currentOrganizationId}`,
            '_blank',
            'noopener noreferrer',
        );
    }

    getBillingDetails(): void {
        this.userService
            .getBillingDetails()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (resp) => {
                    this.billingDetails = resp;
                    if (this.isBillingModalRequired(resp.billingAddress)) {
                        this.setupModalToCollectBillingAddress();
                    }
                },
                (error) => {
                    this.messageModal.show('Can not get the billing details', 'danger');
                },
            );
    }

    isBillingModalRequired({ country, postal_code }: { country: string; postal_code: string }): boolean {
        const user = this.authService.getUser();
        const billingHasPincodeAndCountry = country && postal_code;
        return !billingHasPincodeAndCountry && user.isOwner() && !user.isOnTrialPlan() && !user.isOnDBCFreemiumPlan();
    }

    updateBillingDetails() {
        const billingContactDetails = this.setBillingContactDetails();
        const billingAddressDetails = this.setBillingAddressDetails();

        this.userService
            .updateBillingDetails({ billingAddress: billingAddressDetails, billingContact: billingContactDetails })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (resp) => {
                    this.messageModal.show('Billing information updated successfully', 'success');
                    this.modalToCollectBillingAddress.hide();
                    this.showBillingNavbar = false;
                },
                (error) => {
                    this.messageModal.show('Error updating billing information', 'danger');
                    this.modalToCollectBillingAddress.hide();
                },
            );
    }

    onClosingBillingModal() {
        this.modalToCollectBillingAddress.hide();
        this.showBillingNavbar = true;
    }

    updateUserDetail() {
        this.userService
            .updateFullNameDetails(this.userDetail)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (resp) => {
                    this.userDetailModal.hide();
                    const user = this.authService.getUser();
                    user.first_name = resp.first_name;
                    user.last_name = resp.last_name;
                },
                (error) => {
                    this.messageModal.show('Error updating full name', 'danger');
                },
            );
    }

    private setBillingAddressDetails() {
        return {
            country: this.billingAddressDetails.country.code,
            line1: this.billingDetails.billingAddress.line1 || '',
            line2: this.billingDetails.billingAddress.line2 || '',
            city: this.billingDetails.billingAddress.city || '',
            state: this.billingDetails.billingAddress.state || '',
            postal_code: this.billingAddressDetails.postalCode,
        };
    }

    private setBillingContactDetails() {
        return {
            phone: this.billingDetails.billingContact.phone || '',
            email: this.billingDetails.billingContact.email || '',
        };
    }

    setupModalToCollectBillingAddress() {
        this.billingAddressDetails.postalCode = '';
        this.billingAddressDetails.country = { name: '', code: '' };
        this.modalToCollectBillingAddress.show();
    }

    checkEmailValidity(email) {
        this.isEmailValid = Utils.emailRegex.test(email);
    }

    handleRole(userGroup) {
        this.newUserModel.user_group = userGroup.value;
    }

    async sendUserInviteOnAddOn(count = 1) {
        if (count > 5){
            this.messageService.show('User not invited, please try again later.', 'danger');
            return;
        }
        this.newUserModel.organization = this.currentOrganizationId;

        this.userService.
            post(this.newUserModel, 'add/?source_cta=' + INVITE_COLLAB_CTA.NAVBAR)
            .pipe(
                catchError(error => {
                    if (error.status === 426) {
                        return timer(1000).pipe(switchMap(() => this.sendUserInviteOnAddOn(count + 1)));
                    } else {
                        return [];
                    }
                }))
            .subscribe(
                (response) => {
                    if (response){
                        this.addUserModal.hide();
                        this.messageService.show('Invitation sent successfully', 'success');
                    }
                },
                (error) => {
                    let message = 'Error creating user';
                    try {
                        const body = error.error;
                        if (body.user_id) {
                            message = 'This user has already been invited';
                        } else {
                            message += `: ${body.detail}`;
                        }
                    } catch (ex) {}
                    this.messageModal.show(message, 'danger');
                }
            );
    }

    sendUserInvite() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, 'InviteTeammates_Sent');
        this.newUserModel.organization = this.currentOrganizationId;
        this.userService
            .post(this.newUserModel, 'add/?source_cta=' + INVITE_COLLAB_CTA.NAVBAR)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (response) => {
                    this.addUserModal.hide();
                    this.messageModal.show('Invitation sent successfully');
                },
                (error) => {
                    if (error.status === 426){
                        this.addUserModal.hide();
                        this.usersAddOnModalMessage = `Your current plan allows you to add only ${this.maxUser} users. However, you can choose to increase the limit if you simply buy more user seats.`;
                        this.addOns.show(ADD_ON_PRODUCT_TYPE.USERS, this.usersAddOnModalTitle, this.usersAddOnModalMessage, 'billing-addon-user-modal-upgrade-cta ', true);
                        return;
                    }

                    let message = 'Error creating user';
                    try {
                        const body = error.error;
                        if (body.user_id) {
                            message = 'This user has already been invited';
                        } else {
                            message += `: ${body.detail}`;
                        }
                    } catch (ex) {}
                    this.messageModal.show(message, 'danger');
                },
            );
    }

    toggleSidebarMenu(menuItem: string, $event) {
        if (this.sidebarMenu.has(menuItem)) {
            this.sidebarMenu.delete(menuItem);
        } else {
            if (this.sidebarMenu.size >= 1) {
                this.sidebarMenu.clear();
            }
            this.sidebarMenu.add(menuItem);
        }
        $event.stopPropagation();
    }

    handleAddUserClick(from) {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.INVITE_TEAMMATES_CLICKS, {
            value: from
        });
        if (!this.hasMultiUserAccess || (this.isOnTrialPlan && this.currentUserCount >= this.maxUser)) {
            this.upsellService.showV2({ feature: 'multi_user', source: AMPLITUDE_EVENTS.INVITE_TEAMMATES_UPGRADES });
            return;
        }
        this.userRole = this.userRolesList[0];

        this.newUserModel = {
            username: '',
            organization: this.authService.getCurrentOrgId(),
            user_group: this.userRole.value,
            customer_plan: this.authService.getUser().customer_plan,
            permissions: {
                ...{ cards: false },
                ...{ qr: true },
            },
        };
        this.addUserForm.resetForm(this.newUserModel);
        if (!this.addUserModal.isShown) {
            this.addUserModal.show();
        }
    }

    populateUserRolesList() {
        const user = this.authService.getUser();
        if (user.isOwner() || user.isAdmin(this.authService.getCurrentOrgId())) {
            this.userRolesList = [
                { name: 'Administrator', value: 'AD' },
                { name: 'Editor', value: 'SB' },
                { name: 'Viewer', value: 'RO' },
            ];
        } else if (user.isEditor(this.authService.getCurrentOrgId())) {
            this.userRolesList = [
                { name: 'Editor', value: 'SB' },
                { name: 'Viewer', value: 'RO' },
            ];
        } else {
            this.userRolesList = [{ name: 'Viewer', value: 'RO' }];
        }
    }

    showUpsellModel() {
        const showTrialSwitch = this.authService.getUser().isOnSoloTrial();
        const primaryCta = showTrialSwitch ? 'Try Team plan' : 'Upgrade Plan';
        this.upsellService.show(
            'Upgrade to team/business plan',
            'To create fresh new templates for your business cards, we recommend upgrading to the team/business plan',
            null,
            primaryCta,
            'upsell-template-mgmt',
            null,
            null,
            null,
            null,
            showTrialSwitch ? 'TM' : '',
        );
    }

    onMasterOrgClick() {
        this.onOrgChanged(this.masterOrgReduced);
        this.isMasterOrgSelected = true;
    }

    hasAccessToMasterOrg() {
        return (
            this.authService.getUser().isSuperAdmin() ||
            this.authService.getUser().isSuperEditor() ||
            this.authService.getUser().isSuperViewer()
        );
    }

    showDiscountModal() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.KNOW_MORE_DISCOUNT);
        this.upsellService.showDiscountModal({feature: 'spu', reload: true});
    }

    hideAnnouncementBanner() {
        this.showAnnouncementBanner = false;
    }

    showTrialUpgradeBanner() {
        return this.isOnTrialPlan && this.isOwner && this.currentDashboard === DASHBOARD_MENU.QRCODES;
    }

    logOverviewClicks() {
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.OVERVIEW_NAV_CLICKS);
        this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId }});
    }

    onTrialModalSkipClick() {
        this.trialSelectModal.hide();
        this.isSideDrawerOpen = true;

        // forcefully routing back to QR dashboard if user chooses not to opt for DBC Trial
        if (this.currentDashboard === DASHBOARD_MENU.QRCODES) {
            this.toggleDashboard(this.dashboardMenu[0]);
        }
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            this.toggleDashboard(this.dashboardMenu[1]);
        }
    }

    protected readonly FEATURE_FLAGS = FEATURE_FLAGS;
    protected readonly PWA_MOBILE_NUDGE_SOURCE = PWA_MOBILE_NUDGE_SOURCE;
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        const Hammer = (window as any).Hammer;
        delete Hammer.defaults.cssProps.userSelect; // allow text selection
        return new Hammer(element, {
            touchAction: 'pan-y', // allow scroll etc.
        });
    }
}
