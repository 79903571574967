import {Injectable} from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import {environment} from '../../environments/environment';
import { AuthService } from './auth.service';
@Injectable()
export class LoggerService {
    private initialized = false;
    private user: any = undefined;
    constructor( private authService: AuthService) {

        datadogLogs.init({
            clientToken: 'pub56247d9db7da4ca05935a36b40e2169a',
            site: 'datadoghq.com',
            service: 'beaconstac-dashboard-' + environment.envName,
            forwardErrorsToLogs: true,
            sampleRate: 100,
        });
        this.initialized = true;
        this.user = this.authService.getUser();
    }

    public debug(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.debug(message, context);
        }
    }

    public info(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.info(message, context);
        }
    }

    public warn(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.warn(message, context);
        }
    }

    public error(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.error(message, context);
        }
    }

    public log(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.log(message, context);
        }
    }

    private addUserIdToLog(context){
        if ( this.user ) {
            context.userid =   this.user.id
        }
    }
}
